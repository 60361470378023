/*
  H E L P  M E  C H O O S E  C A R D S
  Help me choose cards

  :description: 

*/

//
//  :code
import { getLocalisedText } from '../../../js/helpers'
import { AllInOneSlimV2 } from '../buttons/All-in-one-slim-v2'
import { EkyPrice } from '../eky-price/EkyPrice'

export const HelpMeChooseCards = ({ config, displayMode, primaryBrandColour, showPrices, showLabels }) => {
  if (!config.mapping['help-me-choose']) {
    return
  }

  const helpMeChooseObject = config.mapping['help-me-choose']

  let helpMeChooseElementsArray = []

  for (const [key, value] of Object.entries(helpMeChooseObject)) {
    helpMeChooseElementsArray.push(
      <div key={key} className="max-w-[24rem] min-h-[12rem] flex justify-center items-center bg-[#f5d3e5] rounded-lg">
        <div className="w-full flex h-full flex-1 flex-col items-center justify-center">
          <div className="text-center p-2 w-full max-w-[89%]">
            <p style={{ color: primaryBrandColour }} className="font-medium text-lg mb-2">
              {getLocalisedText(key, config)}
            </p>
            <div className="flex justify-center">
              <AllInOneSlimV2
                arid={value.arid}
                buttonType={'hover'}
                buttonText={'AR Expert'}
                QRType={'avatar'}
                displayMode={displayMode}
                buttonTextStyles={'text-sm md:text-[90%]'}
                primaryBrandColour={primaryBrandColour}
                config={config}
                type={'expert'}
              ></AllInOneSlimV2>
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-center items-center  p-2">
          <div className="bg-white rounded-full flex justify-center items-center w-[9rem] h-[9rem] sm:w-[11rem] sm:h-[11rem]">
            <img className="sm:w-[7.6rem] sm:h-[8rem] w-[5.7rem] h-[6rem] rounded-lg" src={value.image} alt="" />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="flex items-center justify-center mt-2 w-[90%] lg:w-[80%] xl:max-w-[1500px] ">
        {helpMeChooseElementsArray.length > 2 && (
          <div className="grid gap-x-6 gap-y-6 xl:gap-x-[5rem] xl:gap-y-[2.5rem] grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 mb-[4rem] items-center relative">
            {helpMeChooseElementsArray}
            {showPrices && (
              <EkyPrice config={config} section={'expert'} customPosition={'right-[39%] top-[1%]'}></EkyPrice>
            )}
            {showLabels && (
              <EkyPrice
                config={config}
                section={'expert-without-price'}
                customPosition={'right-[39%] top-[1%]'}
              ></EkyPrice>
            )}
          </div>
        )}
        {helpMeChooseElementsArray.length < 3 && (
          <div className="mb-[4rem] flex justify-evenly w-full relative">
            {helpMeChooseElementsArray}
            {showPrices && (
              <EkyPrice config={config} section={'expert'} customPosition={'right-[1%] top-[1%]'}></EkyPrice>
            )}
            {showLabels && (
              <EkyPrice
                config={config}
                section={'expert-without-price'}
                customPosition={'right-[-7%] top-[1%]'}
              ></EkyPrice>
            )}
          </div>
        )}
      </div>
    </>
  )
}
