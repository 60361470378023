/*

  l o a d e r
  loader

  :description:
  A file to handle loading the feed json into redux

*/

//
//  :code:
import { _isDev } from "../../js/utils"
// import { shuffleArray } from "./redux/showcaseAPI"
import { talk } from "../../js/talk"
import { jsonGetRequestSafe } from "../../js/requests"

//
//  :statics:
const group = "[AR-SHOWCASE]"
const URL_PREFIX_MAP = {
  "dev": "https://ar-showcase-api-dev.pointandplace.com/v2",
  "prod": "https://ar-showcase-api.pointandplace.com/v2"
}

//
//  :env:
const prefix = _isDev() ? URL_PREFIX_MAP.dev : URL_PREFIX_MAP.prod;

export const loadFromCloudSafe = async (language, uuid) => {
  const url = `${prefix}/${uuid}/${language}/app/products/all`
  const response = await jsonGetRequestSafe(url)

  if (!response.data || response.status === 400) {
    talk(`${group} Something went wrong with your request: ${url}, ${response.data}'`) 
    return null
  }

  //
  //  Randomise the product order.
  // if(Array.isArray(response.data)){
  //   shuffleArray(response.data)
  // }

  return response;
}

export const loadConfigFromCloud = async (language, uuid) => {
  const url = `${prefix}/${uuid}/${language}/app/config`
  const response = await jsonGetRequestSafe(url)

  if (!response.data || response.status === 400) {
    talk(`${group} Something went wrong with your request: ${url}, ${response.data}'`) 
    return null
  }
  return response;
}