/*

  u t i l s
  Utils

  :description:
  Generic utility functions.

*/

import { APP_CORE } from "../features/showcase/app-core/redux/memory";

//
//  :code:

//
//  :exports:
export const isDefined = (value) => { return typeof value !== "undefined" }

export const isTruthy = (value, noIsTruthy) => {
  if (typeof value === 'undefined') { return false; }
  if (value === undefined || value === null) { return false; }
  if (value === '') { return false; }
  if (value === 0) { return false; }
  if (value === 0.0) { return false; }
  if (typeof value === "object") { if (Object.keys(value).length === 0) { return false } }
  if (value === false) { return false }
  if (value === "false") { return false }
  if (typeof value === "string") { if (value.length === 0) { return false; } }
  if (value === "no" && !noIsTruthy) { return false }
  if (value === "null") { return false }
  if (value === "undefined") { return false }
  if (value === null) { return false }
  return true;
}

export const getModelDeliveryURL = (arid, env) => {
  if (APP_CORE.env.hasBeenScanned) {
    return APP_CORE.qr.delivery("ar-models", `${arid}`, env)
  }
}
export const getAvatarDeliveryURL = (arid, env) => {
  if (APP_CORE.env.hasBeenScanned) {
    return APP_CORE.qr.delivery("ar-assistant", `${arid}`, env)
  }
}

export const launchIntoAR_ = (service, arid, env, addToCartURL, product, primaryBrandcolour) => {
  if (!arid) { return; }

  if (APP_CORE.env.isAndroid()) {
    APP_CORE.android.launch(service, arid, env)
  }

  if (APP_CORE.env.isiOS()) {
    const QL_OPTIONS = {
      banner: {
        id: "default",
        size: "small",
        options: {
          textHeading: "",
          textContent: "",
          textButton: getLocalisedString("button-add-to-cart") || "Add to Basket",
          colourText: primaryBrandcolour || "#4c13a2",
          colourButtonText: "#ffffff",
          colourBackground: "#ffffff",
          colourButtonBackground: primaryBrandcolour || "#4c13a2",
        }
      },
      callback: () => { window.open(`${addToCartURL || product["add-to-cart-url"]}`, "_blank") },
    }

    if (product) {
      QL_OPTIONS.banner.options.textContent = product.name.length > 96 ? product.name.substring(0, 96) + "..." : product.name
      QL_OPTIONS.banner.options.textHeading = product.brand
    }

    if (service === "ar-models") {
      APP_CORE.ios.launch(service, arid, env, QL_OPTIONS)
    } else {
      APP_CORE.ios.launch(service, arid, env)
    }
  }
}

export const getPath = (distributor, language, page) => { return `/${distributor}/${language}/${page}` }

export const getLocalisedString = (id) => { return APP_CORE.localiser.getLocalisation(id) }

export const _isDev = () => {
  const knownDevEnvironments = ["localhost", "0.0.0.0", "ar-showcase-dev.pointandplace.com", "eyekandy-clamps", "ar-showcase.pointandplace.com"];
  const currentURL = document.location.href;
  return knownDevEnvironments.some(env => currentURL.includes(env));
}