/*

  r o u t e r
  Router

  :description:
  A file to hold our React DOM Router.

  Note, this file will import lots of other files.

*/

//
//  :react & redux:
import React from 'react';
import { createBrowserRouter, } from "react-router-dom";

//
//  :routes:
// import { App } from '../App';
import { AppV2 } from '../AppV2';
import NotFound from './not-found/NotFound';

//
//  :exports:
export const KEYWORDS = {
  expert: "expert",
  filter: "filter",
  search: "search"
}

export const LANGUAGES = {
  "ie": "Irish",
  "fr": "French",
  "en": "English"
}

export const KNOWN_LANGUAGES = Array.from(Object.keys(LANGUAGES))

export const PAGES_TO_NAMES = {
  samsung: "samsung",
  search: "search",
  expert: "expert",
  landing: "landing",
}

export const PAGES_TO_PATHS = {
  samsung: "/:distributor/:language/:page",
  search: "/:distributor/:language/:page",
  expert: "/:distributor/:language/:page",
  landing: "/:distributor/:language/:page",
}

export const PAGE_NAMES = Array.from(Object.keys(PAGES_TO_NAMES))

export const PATHS = {
  app: "/:distributor/:language/:page",
  any: "*",
}

//
//  Note, we only actually have 1 route, everything else is handled by our app.
export const ROUTER = createBrowserRouter([
  { path: PATHS.app, element: <AppV2 /> },
  { path: PATHS.any, element: <NotFound /> }
]);
//
//  This will give the entire tool an "App" like feel, rather than a traditional browsing
//  experience.
//
//  "Pages" are in "routes/page-name/Page.jsx", they can be added here, but must be routed/defined
//  within our app to function.
//
//  In time this approach might change, but although this is a "standard page" now, it'll likely quickly spiral 
//  into custom logic for every distributor.
