/*

  A P P  V2
  appV2

  :description:
  This file now represents our App at the very top level.
  
  As we can't really define our routes in advance as they may not always be uniform or predictable, so 
  some higher-order logic is required to know "which page to load".

  In addition we're powered by a feed, so no feed -> no app. That means that in a sense we need two sets of
  parameters to load any page, our "products" and our "distributor/language/page" values. 

  Likely in future on top of this we'll just have the app reach out to an API to get a "config" object,
  but that's not yet required.

*/

//
//  :react & redux:
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

//
//  :code:
import { KNOWN_LANGUAGES, PAGES_TO_NAMES, PAGE_NAMES } from './routes/routing'
import {
  selectProducts,
  selectLoadFromCloud,
  loadFromCloudAsync,
  selectConfigLoadFromCloud,
  selectConfig,
  loadConfigFromCloudAsync,
} from './features/showcase/redux/showcaseSlice'

//
//  :components:
//  Individual components that we may need to use.
import Page from './components/layout/page/Page'
import { Spinner } from './components/ui/spinner/Spinner'

//
//  :routes:
//  Components that functions as "routes/pages" of our app.
import Landing from './routes/landing/Landing'
import NotFound from './routes/not-found/NotFound'
import Search from './routes/search/Search'
import Samsung from './routes/samsung/Samsung'
import {
  configureAppCore,
  configureLogging,
  initialiseAppCoreAsync,
  selectInitialiseAppCore,
  selectIsConfigured,
  selectLoggingIsConfigured,
} from './features/showcase/app-core/redux/appCoreSlice'
import { APP_CORE } from './features/showcase/app-core/redux/memory'
import { _isDev } from './js/utils'

/**
 * Our main wrapper for our app.
 * This "App" requires a product feed to display, we will handle this at the very top level.
 * @param {Object} props React compatible properties.
 * @returns Our complete, running app.
 */
export const AppV2 = props => {
  //
  //  :react:
  //  Read the inputs from the current URL.
  const { distributor, language, page } = useParams()
  let [searchParams] = useSearchParams(window.location.href)

  //
  //  :redux:
  //  Set up functions/calls for redux.
  const dispatch = useDispatch()
  const loadFromCloud = useSelector(selectLoadFromCloud)
  const products = useSelector(selectProducts)
  const loadConfigFromCloud = useSelector(selectConfigLoadFromCloud)
  const config = useSelector(selectConfig)
  const isConfigured = useSelector(selectIsConfigured)
  const initialisedAppCore = useSelector(selectInitialiseAppCore)
  const loggingIsConfigured = useSelector(selectLoggingIsConfigured)
  const productsRequestHasErrored = () => {
    return loadFromCloud.status === 'errored'
  }
  const configRequestHasErrored = () => {
    return loadConfigFromCloud.status === 'errored'
  }
  //
  //  :helpers:
  // eslint-disable-next-line
  const showPrices = () => {
    return searchParams.has('eky-prices')
  }
  const showLabels = () => {
    return searchParams.has('eky-labels')
  }
  // eslint-disable-next-line
  const hasEkyLanguage = () => {
    return searchParams.has('eky-language')
  }
  // eslint-disable-next-line
  const validateLanguage = value => {
    return KNOWN_LANGUAGES.includes(value)
  }
  // eslint-disable-next-line
  const validateDistributor = value => {
    return _validateStringExists(value)
  }
  const validatePage = value => {
    return _validateStringExists(value)
  }
  const pageIsKnown = value => {
    return PAGE_NAMES.includes(value)
  }
  const getFontFamily = () => {
    if (config) {
      return config.styling['body-font']
    }
  }
  const isLoading = () => {
    return loadFromCloud.requesting === true || loadConfigFromCloud.requesting === true
  }
  const getConfigStyles = () => {
    if (config) {
      return config.styling
    }
  }
  const isEnabledPage = page => {
    if (config) {
      if (config.core) {
        return config.core['enabled-pages'].includes(page)
      }
    }
  }
  const hasLoaded = () => {
    return (
      loadFromCloud.status === 'idle' &&
      !loadFromCloud.error &&
      loadConfigFromCloud.status === 'idle' &&
      !loadConfigFromCloud.error
    )
  }

  //
  //  :useEffects:
  useEffect(() => {
    //
    //  +:logging:
    if (loggingIsConfigured) {
      APP_CORE.log.send({}, 'logAppStart')
    }
  }, [loggingIsConfigured])

  useEffect(() => {
    //
    //  :app-core:
    //  We need to first configure app core.
    dispatch(configureAppCore())
  }, [dispatch])

  useEffect(() => {
    //
    //  Once configured, we can then handle initialising app-core.
    if (isConfigured) {
      dispatch(initialiseAppCoreAsync())
    }
  }, [dispatch, isConfigured])

  useEffect(() => {
    //
    // Once configured, scan our environment
    if (isConfigured) {
      APP_CORE.env.scan()
    }
  }, [isConfigured])

  //
  //  This is being delt with while getting the localised string, we should be able to remove this
  useEffect(() => { 
    //
    //  Currently two different methods for setting the language of APP_CORE, here we handle both. 
    //  eky-language will always come first
    if (showPrices() && hasEkyLanguage()) {
      APP_CORE.localiser.language = searchParams.get('eky-language')
      return
    }
    if (config) {
      if (!config.core) {
        return
      }
      if (initialisedAppCore) {
        const LANGUAGE_MAP = {
          'ie': 'en-GB',
          'fr': 'fr-FR',
          'en': 'en-GB'
        }
        APP_CORE.localiser.language = LANGUAGE_MAP[config.core.language] || 'en-GB'
      }
    }
  }, [config, initialisedAppCore, searchParams, isConfigured, showPrices, hasEkyLanguage])

  //
  //  :init:
  //  On startup we want to load our products at the very top of our app.
  useEffect(() => {
    const go = async () => {
      //
      //  Ensure that we actually have a value for distributor/language.
      if (!validateDistributor(distributor) || !validateLanguage(language)) {
        return false
      }
      //
      //  There is no point in loading content that is not for a known page.
      if (!pageIsKnown(page)) {
        return false
      }
      //
      //  If we are not already requesting and there is no error.
      if (loadFromCloud.requesting || loadFromCloud.error) {
        return false
      }
      if (loadConfigFromCloud.requesting || loadConfigFromCloud.error) {
        return false
      }

      //
      //  If we do not have any products/config and have not yet requested.
      if (loadFromCloud.response || loadFromCloud.status) {
        return false
      }
      if (loadConfigFromCloud.response || loadConfigFromCloud.status) {
        return false
      }

      //
      //  Dispatch the actions that will load our products and config.
      dispatch(loadFromCloudAsync({ language: language, uuid: distributor }))
      dispatch(loadConfigFromCloudAsync({ language: language, uuid: distributor }))
    }
    go()
  }, [dispatch, loadFromCloud, distributor, language, page, validateDistributor, validateLanguage, loadConfigFromCloud])

  useEffect(() => {
    //
    //  We need to configure logging using app core.
    if (initialisedAppCore && isConfigured) {
      const loggingConfig = {
        distributor: distributor,
        language: language,
        location: page,
        user: APP_CORE.readBrowserConfig('user'),
        session: APP_CORE.readBrowserConfig('session'),
        environment: APP_CORE.readBrowserConfig('environment'),
      }
      dispatch(configureLogging(loggingConfig))
    }
  }, [initialisedAppCore, dispatch, distributor, page, language, isConfigured])

  //
  //  :mouse flow:
  useEffect(() => {
    if (window._mfq && config && !_isDev()) {
      if (config.core) {
        window._mfq.push(['setVariable', 'distributor', config.core.uuid])
        window._mfq.push(['setVariable', 'retailer', config.core.name])
        window._mfq.push(['setVariable', 'language', config.core.language])
        window._mfq.push(['setVariable', 'page', page])
      }
    }
  }, [distributor, language, page, config])

  //
  //  :validation:
  //  Functions to assist with validating parameters.
  const _validateStringExists = value => {
    if (typeof value !== 'string') {
      return false
    }
    return value.length > 0
  }
  const inputParmatersAreValid = () => {
    const results = {
      distributor: validateDistributor(distributor),
      language: validateLanguage(language),
      page: validatePage(page),
    }
    return (results.distributor && results.language && results.page) === true
  }

  //
  //  :jsx:
  //  Handle writing our JSX.
  const renderRouteComponentToDOM = () => {
    //
    //  :step 0:
    //  Define our global props to pass.
    const PROPS = {
      distributor: distributor,
      language: language,
      config: {},
      page: page,
      products: [],
      showPrices: showPrices(),
      showLabels: showLabels(),
      styling: getConfigStyles(),
    }

    //
    //  :step 1:
    //  If our page is found in our page to component map, we can just return it from here.
    if (pageIsKnown(page) && isEnabledPage(page) && initialisedAppCore.ready && isConfigured) {
      //
      //  Upsert our current products and config from redux into the payload.
      PROPS.products = products
      PROPS.config = config
      //
      //  Add a fade in block.
      setTimeout(() => {
        document.querySelector('.route-container').classList.remove('opacity-0')
      }, 275)
      //
      //  Return a div containing all of our routes, only 1 can display at a time.
      return (
        <div className={`route-container opacity-0 transition-opacity w-full h-full relative ${getFontFamily()}`}>
          {page === PAGES_TO_NAMES.landing && <Landing {...PROPS}></Landing>}
          {page === PAGES_TO_NAMES.search && <Search {...PROPS}></Search>}
          {page === PAGES_TO_NAMES.samsung && <Samsung {...PROPS} brand={PAGES_TO_NAMES.samsung}></Samsung>}
          <div id="register-tailwind-styles" className="hidden bg-[#001d3d] bg-[#4c13a2] bg-[#0046be]"></div>
          <div className='absolute right-0 text-[#b5b2b1] text-[14px]'><p>Powered by eyekandy</p></div>
        </div>
      )
    }
    //
    //  :step 2:
    //  The page we are being asked to load is unknown to us, display 404.
    setTimeout(() => {
      document.querySelector('.route-container').classList.remove('opacity-0')
    }, 275)
    return (
      <div className="route-container opacity-0 transition-opacity w-full h-full">
        <NotFound {...PROPS}></NotFound>
      </div>
    )
  }

  //
  //  :render:
  //  Our render function.
  return (
    <>
      {/*  
        :container:
        Keep everything inside our Page component.
    */}
      <Page>
        {/*  
          :validation:
          If there is something wrong with our input parameters, we need to forward to an error page via Navigate.
      */}
        {!inputParmatersAreValid() && <></>}

        {/*  
          :loading:
          Display a loading animation/svg.
      */}
        {isLoading() && (
          <div className="h-screen w-full flex justify-center items-center">
            <Spinner></Spinner>
          </div>
        )}

        {/*  
          :products-request-error:
          There was enough information to make the request but it has failed for some reason.
      */}
        {productsRequestHasErrored() && <h1>PRODUCTS ERRORED</h1>}

        {/*  
          :config-request-error:
          There was enough information to make the request but it has failed for some reason.
      */}
        {configRequestHasErrored() && <h1>CONFIG ERRORED</h1>}

        {/*  
          :ready:
          There was enough information to make our requests, and now have their successful responses we are "loaded".
          We just need to pass down our configuration options to our "Page/Route" level components.
      */}
        {hasLoaded() && renderRouteComponentToDOM()}
        {!pageIsKnown(page) && renderRouteComponentToDOM()}
      </Page>
    </>
  )
}
