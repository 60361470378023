/*

  c o n t e n t
  Content

  :description:
  A component to act as the main area for our content.
  
*/

//
//  :react & redux:
import React from 'react';

//
//  :code:
import './Content.css';

//
//  :packages:

//
//  :component:
const Content = (props) => {
  return (
    <div className='eky-content flex w-full min-h-full justify-center'>
      <div className='flex flex-col justify-center w-full items-center'>
        {props.children}
      </div>
    </div>
  );
}

export default Content;
