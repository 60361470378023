/*

  s a m s u n g
  Samsung

  :description:
  
*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { derivePopularProductsByBrand } from '../../js/helpers'

//
//  :code:
import { SamsungUI } from './SamsungUI'
import { APP_CORE } from '../../features/showcase/app-core/redux/memory'

//
//  :statics:
const MAX_PRODUCTS = 8

//
//  :component:
const Samsung = props => {
  //
  //  :state:
  const [popularSamsungProducts, setPopularSamsungProducts] = useState([])
  //
  //  :config:
  //  Add our top level config here.
  const { brand, distributor, language, config, products, page, styling } = props

  //
  //  :redux:
  //  Set up functions/calls for redux.
  useEffect(() => {
    //
    //  +:logging:
    APP_CORE.log.send({ brand: 'samsung' }, 'logDeliveryBrandPage')
  }, [])

  useEffect(() => {
    setPopularSamsungProducts(
      derivePopularProductsByBrand(products, 'samsung', MAX_PRODUCTS)
    )
  }, [products])

  //
  //  :data:
  //  We might want to filter down our products here before passing them.
  const brandProducts = products

  //
  //  :render:
  //  Render out our UI component.
  return (
    <SamsungUI
      products={brandProducts}
      distributor={distributor}
      language={language}
      config={config}
      page={page}
      brand={brand}
      popularProducts={popularSamsungProducts}
      styling={styling}
    ></SamsungUI>
  )
}

export default Samsung
