//
//  :APP_CORE:
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'

//
//  :react:
import { useNavigate } from 'react-router-dom'

//
//  :statics:
import { LOCALISATION_ID_ENUMS } from '../../../statics'
import { PAGES_TO_NAMES } from '../../routing'

//
//  :utils:
import { getPath } from '../../../js/utils'

//
//  :helpers:
import { convertRetailerNameToClass, getLocalisedText } from '../../../js/helpers'
import { CarouselV2 } from '../../../components/layout/carousel/CarouselV2'

//
//  :render:
export const ShopByCategory = ({ config, styling, language, distributor, categoriesTodisplay }) => {
  const navigate = useNavigate()
  if (!config) {
    return
  }
  if (!categoriesTodisplay){
    return
  }
  //
  //  On Click handler
  const onClickHandler = category => {
    window.scrollTo(0, 0)
    document.querySelector('#root').classList.add('opacity-0')
    //
    //  +:logging:
    APP_CORE.log.send({ category: category }, 'logSearchByCategory')

    setTimeout(() => {
      navigate(getPath(distributor, language, PAGES_TO_NAMES.search) + `?c=${category}`)
      setTimeout(() => {
        document.querySelector('#root').classList.remove('opacity-0')
      }, 180)
    }, 250)
  }

  //
  //  Get the title styles for this retailer
  const titleStyleClass = () => {
    if (styling) {
      if (styling['title-font']) {
        return `text-2xl ${styling['title-font']}`
      }
    }
  }

  const categories = []

  categoriesTodisplay.map(category => {
    categories.push(
      <div
        key={category.category}
        alt={category.category}
        title={category.category}
        className={`${convertRetailerNameToClass(config)}-shop-by-card-container select-none`}
      >
        <div className={`${convertRetailerNameToClass(config)}-shop-by-cat-images-container`}>
          <img
            className={`${convertRetailerNameToClass(config)}-shop-by-images`}
            draggable="false"
            src={category.image}
            alt=""
            onClick={e => {
              onClickHandler(category.category)
            }}
          />
        </div>
        <div className="flex justify-center text-center mt-3 category-text">
          <p className="text-[16px] font-normal select-none">{category.category}</p>
        </div>
      </div>
    )
    return true;
  })

  return (
    <>
      <div className="flex justify-center text-center mb-[1rem] font-medium ">
        <h2 className={titleStyleClass()} style={{ color: styling['title-text-colour'] }}>
          {getLocalisedText(LOCALISATION_ID_ENUMS.titleShopByCategory, config)}
        </h2>
      </div>
      <div className="w-full justify-center relative w-[90%] lg:w-[80%] items-center">
        <CarouselV2 setWidth={false} config={config}>
          {categories}
        </CarouselV2>
      </div>
    </>
  )
}
