/*

  i n d e x
  Index

  :description:
  Our main file, handles defining the app, binding the Redux store, and the routing for browsers.

  :versions:
  - react: v18
  - react-router: v6
  - redux: v8
  
*/

//
//  :react & redux:
import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { RouterProvider } from "react-router-dom";

//
//  :code:
import { ROUTER } from './routes/routing'
import { store } from './app/store';
import './index.css';
import { talk } from './js/talk';

//
//  :packages:
import reportWebVitals from './reportWebVitals';

//
//  :app:
talk(`[ROOT] defining the app via ReactDOM.createRoot @ #root`)
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={ROUTER} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
