import { APP_CORE } from '../features/showcase/app-core/redux/memory'
import { isTruthy } from './utils'

export const getUniqueCategoriesFromFeed = products => {
  if (!Array.isArray(products)) {
    return []
  }
  if (!isTruthy(products)) {
    return []
  }

  let categories = []
  products.map(product => {
    if (isTruthy(product.category)) {
      if (!categories.includes(product.category)) {
        categories.push(product.category)
      }
    }
    return true
  })

  return categories
}

export const getUniqueBrandsFromFeed = products => {
  if (!Array.isArray(products)) {
    return []
  }
  if (!isTruthy(products)) {
    return []
  }

  let brands = []
  products.map(product => {
    if (isTruthy(product.brand)) {
      if (!brands.includes(product.brand)) {
        brands.push(product.brand)
      }
    }
    return true
  })

  return brands
}

export const getPopularProductsFromFeed = (products, promoted) => {
  if (!Array.isArray(products)) {
    return []
  }
  if (!isTruthy(products)) {
    return []
  }
  if (!isTruthy(promoted)) {
    return []
  }

  let popularProducts = []
  const knownIdentifiers = ['skus', 'eans']
  const id_enums = {
    skus: 'sku',
    eans: 'ean',
  }

  knownIdentifiers.map(identifier => {
    if (promoted[identifier].length > 0) {
      if (popularProducts.length < 8) {
        products.map(product => {
          if (promoted[identifier].includes(product[id_enums[identifier]])) {
            if (popularProducts.length < 8) {
              popularProducts.push(product)
            }
          }
          return true
        })
      }
    }
    return true
  })

  return popularProducts

  //
  //  for now we do not have specificed popular products, so we can select 8
  /*
  let popularProducts = []
  const knownPopularProductSkus = promoted.skus

  for (let i = 0; i < products.length; i++) {
    if (popularProducts.length === 8) { break; }
    if (knownPopularProductSkus.includes(products[i].sku)) {
      popularProducts.push(products[i])
    }
  }
  return popularProducts;
  */
}

export const getPopularProductsByBrand = (products, brand) => {
  if (!Array.isArray(products)) {
    return []
  }
  if (!isTruthy(products)) {
    return []
  }
  let popularProducts = []
  //
  // TEMP
  for (let i = 0; i < products.length; i++) {
    if (products[i].brand === brand) {
      popularProducts.push(products[i])
    }
    if (popularProducts.length === 8) {
      break
    }
  }
  return popularProducts
}

export const derivePopularProductsByBrand = (products, brand, MAX_PRODUCTS) => {
  let brandProducts = []
  let duplicateAridCheck = []

  //
  //  :step 1:
  //  Get all of the brand products
  products.map(product => {
    //
    //  check duplicates
    if (product.brand.toLowerCase() === brand.toLowerCase() && !duplicateAridCheck.includes(product.arid)) {
      duplicateAridCheck.push(product.arid)
      brandProducts.push(product)
    }
    return true
  })

  //
  //  :step 3:
  //  Sort the brand products by rank
  brandProducts.sort((a, b) => {
    return a.rank - b.rank
  })

  return brandProducts.splice(0, MAX_PRODUCTS)
}

export const getLocalisedText = (id, config) => {
  if (!id) {
    return
  }
  const LANGUAGE_MAP = {
    ie: 'en-GB',
    fr: 'fr-FR',
    en: 'en-GB',
  }
  if (config) {
    if (config.core) {
      APP_CORE.localiser.language = LANGUAGE_MAP[config.core.language] || 'en-GB'
      return APP_CORE.localiser.getLocalisation(id)
    }
  }
  return APP_CORE.localiser.getLocalisation(id)
}

export const isSectionEnabled = (section, page, config) => {
  return config.pages[page]['enabled-sections'].includes(section)
}

export const convertRetailerNameToClass = config => {
  if (config) {
    if (config.core) {
      return config.core.name.replaceAll(' ', '-')
    }
  }
}

export const currentLocation = (config, page) => {
  if (config.core['live-url']) {
    if (config.core['live-url'].localeCompare(document.referrer) > -1) {
      return `${config.core['live-url']}#${page}`
    }
  }

  return null
}

export const deriveCategoriesForDisplay = products => {
  //
  // Check if `products` is an array and not empty
  try {
    Array.from(products)
  } catch (error) {
    return []
  }

  let categories = []

  //
  // Iterate over each product to derive unique categories
  products.forEach(({ category, image }) => {
    if (
      category !== undefined &&
      category !== null &&
      category !== '' &&
      !categories.some(cat => cat.category === category)
    ) {
      //
      // Push category and image
      categories.push({ category, image })
    }
  })

  //
  //  Return to sender
  return categories
}

//
//  Get the price text for the popular product card, some retailers require further formatting
export const getPriceText = (price, config) => {
  if (config) {
    if (config.core) {
      //
      //  RDC Price from their feed does not relfect their actual product page price, we need to update it here
      if (config.core.language === 'fr') {
        const floatPrice = parseFloat(price)
        const formattedPrice = floatPrice.toFixed(2).replace('.', ',')
        price = formattedPrice
      }

      const PRICE_STRUCTURE_MAP = {
        en: `${config.core.currency}${price}`,
        ie: `${config.core.currency}${price}`,
        fr: `${price} ${config.core.currency}`,
      }
      return PRICE_STRUCTURE_MAP[config.core.language]
    }
  }
}
