//
//  :react && redux:
import { useEffect, useState } from 'react'
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'

//
//  :code:
import { AddToCart } from '../../../components/ui/buttons/AddToCart'
import { AllInOneSlimV2 } from '../../../components/ui/buttons/All-in-one-slim-v2'
import { EkyPrice } from '../../../components/ui/eky-price/EkyPrice'
import { getLocalisedText, getPopularProductsFromFeed } from '../../../js/helpers'

//
//  :statics
import { LOCALISATION_ID_ENUMS } from '../../../statics'

//
//  :render:
export const PopularProducts = ({ styling, config, displayMode, page, showPrices, products, showLabels }) => {
  const [popularProducts, setPopularProducts] = useState([])

  //
  //  Get the popular products for thie retaielr
  useEffect(() => {
    setPopularProducts(getPopularProductsFromFeed(products, config.promoted))
  }, [products, config])

  if (!styling) {
    return
  }

  //
  //  Get the primary brand colour from the styling block of this retailers config
  const primaryBrandColour = styling['primary-brand-colour']

  //
  //  Return the body font class when needed
  const getBodyTextStyle = () => {
    return styling['body-font']
  }

  //
  //  Return the title styling when needed
  const titleStyleClass = () => {
    return `text-2xl ${styling['title-font']}`
  }

  //
  //  Get the price text for the popular product card, some retailers require further formatting
  const getPriceText = price => {
    if (config) {
      if (config.core) {
        //
        //  RDC Price from their feed does not relfect their actual product page price, we need to update it here
        if (config.core.language === 'fr') {
          const floatPrice = parseFloat(price)
          const formattedPrice = floatPrice.toFixed(2).replace('.', ',')
          price = formattedPrice
        }

        const PRICE_STRUCTURE_MAP = {
          en: `${config.core.currency}${price}`,
          ie: `${config.core.currency}${price}`,
          fr: `${price} ${config.core.currency}`,
        }
        return PRICE_STRUCTURE_MAP[config.core.language]
      }
    }
  }

  //
  //  When the user clicks on the product image or title, a new tab should open with the product page
  const openProductPage = (uri, arid) => {
    const anchor = document.createElement('a')
    anchor.appendChild(document.createElement('img'))
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('rel', 'noreferrer')
    anchor.setAttribute('href', uri)
    anchor.click()

    //
    //  +:logging:
    APP_CORE.log.send({ arid: arid }, 'logClickProductPage')
  }

  //
  //  :step 1:
  //  Render all products as divs.
  const productElements = popularProducts.map(product => (
    <div
      key={product.sku}
      className="relative h-[23rem] w-[15rem] xl:w-[17rem] xl:h-[25rem] flex flex-col justify-center items-center bg-white border-2 border-slate-300 rounded-lg p-6"
    >
      <div className="w-auto h-auto bg-white mt-1 rounded" title={product.name}>
        <img
          className="max-h-[11rem] cursor-pointer"
          onClick={() => {
            openProductPage(product['add-to-cart-url'], product.arid)
          }}
          src={product.image}
          alt=""
        />
      </div>
      <div
        className={`w-[12rem] max-h-[5rem] min-h-[5rem] bg-white m-1 flex flex-col text-center justify-evenly ${getBodyTextStyle()}`}
      >
        <p
          className="text-xs cursor-pointer"
          onClick={() => {
            openProductPage(product['add-to-cart-url'], product.arid)
          }}
        >
          {product.name}
        </p>
        <p className="text-sm mt-2">{getPriceText(product.price)}</p>
      </div>
      <div className="flex justify-center my-2">
        <AllInOneSlimV2
          primaryBrandColour={styling['primary-brand-colour']}
          product={product}
          arid={product.arid}
          buttonType={'hover'}
          displayMode={displayMode}
          uri={product['add-to-cart-url']}
          config={config}
          page={page}
          showPrices={showPrices}
        ></AllInOneSlimV2>
      </div>
      <div className="flex justify-center my-2">
        <AddToCart
          primaryBrandColour={primaryBrandColour}
          uri={product['add-to-cart-url']}
          config={config}
          arid={product.arid}
        ></AddToCart>
      </div>
    </div>
  ))

  return (
    <>
      <div className="w-full h-auto bg-[#f8f8f8] mt-[2rem]">
        <div className="flex justify-center text-center my-[2rem] font-medium">
          <h2 className={titleStyleClass()} style={{ color: styling['title-text-colour'] }}>
            {getLocalisedText(LOCALISATION_ID_ENUMS.titlePopularProducts, config)}
          </h2>
        </div>
        <div className="mx-2 flex justify-center">
          <div className="grid gap-x-6 gap-y-6 xl:gap-x-[4.4rem] xl:gap-y-[2.5rem] grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-[4rem] md:mb-[4rem] relative">
            {showPrices && (
              <EkyPrice
                config={config}
                section={'popularProducts'}
                page={page}
                customPosition={'left-[4%] top-[1%]'}
              ></EkyPrice>
            )}
            {showPrices && (
              <EkyPrice
                config={config}
                section={'ar-models'}
                page={page}
                customPosition={'right-[0%] top-[26%]'}
              ></EkyPrice>
            )}

            {showLabels && (
              <EkyPrice
                config={config}
                section={'popularProducts-without-price'}
                page={page}
                customPosition={'left-[4%] top-[1%]'}
              ></EkyPrice>
            )}
            {showLabels && (
              <EkyPrice
                config={config}
                section={'ar-models-without-price'}
                page={page}
                customPosition={'right-[0%] top-[28%]'}
              ></EkyPrice>
            )}
            {productElements}
          </div>
        </div>
      </div>
    </>
  )
}
