
//
//  :react & redux:
import { useNavigate } from "react-router-dom";
import { APP_CORE } from "../../../features/showcase/app-core/redux/memory";

//
//  :code:
import { getLocalisedText } from "../../../js/helpers";
import { LOCALISATION_ID_ENUMS } from "../../../statics";

//
//  :component:
export const Back = ({ buttonText, classes, primaryBrandColour, config }) => {
  if (!buttonText) { buttonText = "< Back" }
  const navigate = useNavigate();

  const backButtonClickHandler = () => {
    window.scrollTo(0, 0)
    document.querySelector('#root').classList.add('opacity-0')
    //
    //  +:logging:
    APP_CORE.log.send({}, "logClickBack")
    setTimeout(() => {
      navigate(-1)
      setTimeout(() => {
        document.querySelector('#root').classList.remove('opacity-0')
      }, 222)
    }, 333)
  }

  return <>
    <div className={classes ? classes : ''}>
      <button
        onClick={() => {
          backButtonClickHandler()
        }}
        className='text-xl'
        style={{color: primaryBrandColour}}
      >
        <p>{`< ${getLocalisedText(LOCALISATION_ID_ENUMS.buttonBack, config)}`}</p>
      </button>
    </div>
  </>
}