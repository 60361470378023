/*

  t a l k
  Talk

  :description:
  A file to handle logging to the console.

*/

export const talk = (args, level, force, uuid) => {
  if (!level) {
    level = "log"
  }
  const levelToFunctionMap = {
    log: console.log,
    info: console.info,
    warning: console.warn,
    error: console.error,
  }
  const loggingFunction = levelToFunctionMap[level]
  if (!loggingFunction) { throw new Error(`error, unknown logging level '${level}'`) }

  //
  //  TODO: Handle turning off via global silence.
  //  @Louis: Added debug here to always enable.
  //const silence = window.location.href.indexOf("eky-logging") === -1www
  const silence = false

  //
  //  We need to unpack dynamically so ensure we have a list as our args.
  if (!Array.isArray(args)) {
    args = [args]
  }

  //
  //  If we are not silent, or have been instructed to force the message, write to console.
  if (!silence || force) {
    var event = new Date();
    const timeAsString = event.toTimeString().split(" ")[0]
    let tidyTimeString = `[${timeAsString}.${event.getMilliseconds()}]`
    if (uuid) {
      tidyTimeString = `${tidyTimeString} {${uuid}}\n `
    }
    loggingFunction(tidyTimeString, ...args)
  }
}
