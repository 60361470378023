/*
  
  l a n d i n g  u i  v 2
  Landing UI v2

  :description:
  Our UI component of the "Landing page v2" 

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_CORE } from '../../features/showcase/app-core/redux/memory'

//
//  :code:
import { HelpMeChooseCards } from '../../components/ui/help-me-choose-cards/HelpMeChooseCards'
import { SponsoredBanner } from '../../components/ui/sponsored-banner/SponsoredBanner'
import { SmartBanner } from '../../components/ui/smart-banner/SmartBanner'
import { PAGES_TO_NAMES } from '../routing'
import { ShopByCategory } from './components/ShopByCategory'
import { ShopByBrandSection } from './components/ShopByBrand'
import { PopularProducts } from './components/PopularProducts'

//
//  :statics:
import { LOCALISATION_ID_ENUMS } from '../../statics'

//
//  :helpers:
import { getLocalisedText, isSectionEnabled } from '../../js/helpers'

//
//  :utils:
import { getLocalisedString, getPath } from '../../js/utils'

//
//  :svgs:
import { SearchIcon } from '../../components/ui/svgs/SearchIcon'

//
//  :assets:
import videoBannerSrc from './videos/ar-showcase-walkthrough-low-res-002.mp4'

export const LandingUIv2 = ({ distributor, language, config, products, page, showPrices, styling, categoriesTodisplay, showLabels }) => {
  //
  //  :props:
  //  Handle reading from props.
  // eslint-disable-next-line
  const primaryBrandColour = styling['primary-brand-colour']

  const getScreenSize = () => {
    return window.innerWidth >= 1024 ? 'desktop' : 'mobile'
  }

  const [displayMode, setDisplayMode] = useState(getScreenSize())

  const navigate = useNavigate()

  const onResizePageHandler = event => {
    if (getScreenSize() !== displayMode) {
      setDisplayMode(getScreenSize())
    }
  }
  window.addEventListener('resize', onResizePageHandler)

  //
  //  UseEffect for updating the container height of our parent container
  useEffect(() => {
    if (window.parent) {
      if (config.core.resizeContainer) {
        setTimeout(() => {
          window.parent.postMessage({ scrollHeight: document.body.scrollHeight, action: 'update-showcase-embed-height'}, '*')
        }, 600);
      }
    }
  }, [config])

  const searchForProductsInput = () => {
    const onEnterHandler = e => {
      if (e.key === 'Enter') {
        //
        //  cheating here
        window.scrollTo(0, 0)
        document.querySelector('#root').classList.add('opacity-0')
        //
        //  +:logging:
        APP_CORE.log.send({ text: e.target.value }, 'logSearch')
        setTimeout(() => {
          navigate(getPath(distributor, language, PAGES_TO_NAMES.search) + `?q=${e.target.value}`)
          setTimeout(() => {
            document.querySelector('#root').classList.remove('opacity-0')
          }, 180)
        }, 250)
      }
    }
    const onClickHanlder = e => {
      const searchInput = document.getElementById('search-for-products').value

      //
      //  +:logging:
      APP_CORE.log.send({ text: searchInput }, 'logSearch')

      //
      //  cheating here
      window.scrollTo(0, 0)
      document.querySelector('#root').classList.add('opacity-0')
      setTimeout(() => {
        navigate(getPath(distributor, language, PAGES_TO_NAMES.search) + `?q=${searchInput}`)
        setTimeout(() => {
          document.querySelector('#root').classList.remove('opacity-0')
        }, 180)
      }, 250)
    }

    return (
      <>
        <div className="flex justify-between items-center py-1 pr-1 mt-[2rem] mb-[2rem] bg-white border-2 border-slate-500 h-[3rem] w-[77%] sm:w-[61%] md:w-[27rem] lg:w-[33rem] rounded-full transition-all hover:shadow-md">
          <input
            className="outline-none bg-transparent px-4 sm:w-80 w-[90%] md:w-96 lg:w-[30rem]"
            type="text"
            onKeyUp={e => {
              onEnterHandler(e)
            }}
            id="search-for-products"
            placeholder={getLocalisedText(LOCALISATION_ID_ENUMS.inputPlaceholder, config)}
          />
          <div
            className="h-10 w-10 rounded-full cursor-pointer"
            style={{ backgroundColor: primaryBrandColour }}
            onClick={e => {
              onClickHanlder(e)
            }}
          >
            <SearchIcon styles="mt-[0.5rem] ml-[0.5rem]"></SearchIcon>
          </div>
        </div>
      </>
    )
  }

  const bannerVideoEmbed = (type, classes) => {
    return (
      <>
        <div className="hidden md:flex md:flex-col md:w-[90%] md:lg:w-[80%] md:items-center 2xl:max-w-[1700px]">
          <video id="header-banner-video" autoPlay loop muted className={classes}>
            <source src={videoBannerSrc} type={type}></source>
            Your browser does not support the video element. Kindly update it to latest version.
          </video>
        </div>
      </>
    )
  }

  const showPricesHeader = () => {
    return (
      <>
        <h1 className="eyekandy-title mt-5 text-4xl border-b-2">{getLocalisedString('page-title')}</h1>
      </>
    )
  }

  const showLabelHeader = () => {
    return (
      <>
        <h1 className="eyekandy-title mt-5 text-4xl border-b-2">{getLocalisedString('page-title-without-price')}</h1>
      </>
    )
  }

  const sections = () => {
    return (
      <>
        {/* Show pricing header  */}
        {showPrices && showPricesHeader()}
        {showLabels && showLabelHeader()}

        {/* Sponsored banner  */}
        {isSectionEnabled('sponsored-banner', page, config) && (
          <SponsoredBanner
            currentPage={'landing'}
            section={'sponsored'}
            retailer={config.core.name}
            config={config}
            displayMode={displayMode}
            showPrices={showPrices}
            showLabels={showLabels}
          ></SponsoredBanner>
        )}

        {/* Video banner  */}
        {isSectionEnabled('video', page, config) && bannerVideoEmbed('video/mp4', 'mt-2')}

        {/* Search input  */}
        {isSectionEnabled('search', page, config) && searchForProductsInput()}

        {/* Shop by category  */}
        {isSectionEnabled('shop-by-category', page, config) && (
          <ShopByCategory
            config={config}
            styling={styling}
            language={language}
            distributor={distributor}
            displayMode={displayMode}
            categoriesTodisplay={categoriesTodisplay}
          ></ShopByCategory>
        )}

        {/* Shop by brand  */}
        {isSectionEnabled('shop-by-brand', page, config) && (
          <ShopByBrandSection
            config={config}
            styling={styling}
            language={language}
            distributor={distributor}
            displayMode={displayMode}
            showPrices={showPrices}
            showLabels={showLabels}
            page={page}
          ></ShopByBrandSection>
        )}

        {/* Popular products  */}
        {isSectionEnabled('popular-products', page, config) && (
          <PopularProducts
            config={config}
            styling={styling}
            language={language}
            distributor={distributor}
            displayMode={displayMode}
            showPrices={showPrices}
            showLabels={showLabels}
            page={page}
            products={products}
          ></PopularProducts>
        )}

        {/* expert banner  */}
        {isSectionEnabled('expert-banner', page, config) && (
          <SmartBanner group={'expert'} distributor={distributor} language={language} config={config}></SmartBanner>
        )}

        {/* how to choose cards  */}
        {isSectionEnabled('how-to-choose-cards', page, config) && (
          <HelpMeChooseCards
            primaryBrandColour={primaryBrandColour}
            displayMode={displayMode}
            config={config}
            showPrices={showPrices}
            showLabels={showLabels}
          ></HelpMeChooseCards>
        )}
      </>
    )
  }

  return (
    <>
      <div className="flex flex-col w-full min-h-full items-center justify-center relative">{sections()}</div>
    </>
  )
}
