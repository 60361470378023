/*

  s e a r c h  -  p r e s e n t a t i o n  u i  -  s e a r c h  r e s u l t s
  Search PresentationUI Search Results

  :description:

*/

//
//  :react & redux:
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :code:
import {
  initialiseSearchWithProducts,
  renderHashOfSearchState,
  selectSearchState,
  uninitialiseState,
  updateSearchProductsDisplay,
  wipeSearchtext
} from '../../../../features/showcase/redux/showcaseSlice'
import { AddToCart } from '../../../../components/ui/buttons/AddToCart'
import { AllInOneSlimV2 } from '../../../../components/ui/buttons/All-in-one-slim-v2'
import { SearchResultsQR } from '../../../../components/ui/buttons/SearchResultsQR'
import { APP_CORE } from '../../../../features/showcase/app-core/redux/memory'

export const SearchResults = ({ distributor, language, config, products, page, displayMode, styling }) => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const primaryBrandColour = styling["primary-brand-colour"]

  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const dispatch = useDispatch()
  const SEARCH_STATE = useSelector(selectSearchState)

  //
  //  :effect-001:
  //  We always want to signal to redux that we want to initialise our products, but only if the search state
  //  has not already been initialised.
  useEffect(() => {
    if (!SEARCH_STATE.initialised) {
      //console.error('INITIALISING SEARCH STATE')
      dispatch(initialiseSearchWithProducts(products))
      dispatch(updateSearchProductsDisplay())
    }
    if (SEARCH_STATE) {
      //console.log(SEARCH_STATE.hash)
    }
  }, [dispatch, products, SEARCH_STATE])

  //
  //  :effect-002:
  //  If the hash of our search results doesn't match the current hash -> update products for display.
  useEffect(() => {
    if (SEARCH_STATE.hash) {
      if (SEARCH_STATE.hash !== renderHashOfSearchState(SEARCH_STATE)) {
        console.error(
          'SEARCH STATE HASH MISMATCH, UPDATING PRODUCTS FOR DISPLAY'
        )
        dispatch(updateSearchProductsDisplay())
      }
    }
  }, [dispatch, SEARCH_STATE])

  //
  //  :effect-003:
  //  Our hashing mechanism isn't working as when we load we already have a hash.
  //  Let's just say that if there is a query parameter set, we've been signalled to load from get arguments.
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (
      searchParams.get('q') ||
      searchParams.get('b') ||
      searchParams.get('c')
    ) {
      //dispatch(updateFromGetArguments())
    }
  }, [dispatch, SEARCH_STATE])

  //
  //  :effect-004:
  //  When we unmount, make sure to clear the redux state, there's no way to interact it via get arguments in future
  //  if we don't do this now.
  useEffect(() => {
    return () => {
      //dispatch(wipeBrandAndCategoryFilters())
      dispatch(wipeSearchtext())
      dispatch(uninitialiseState())
    }
  }, [dispatch])

  const openProductPage = (uri, arid) => {
    const anchor = document.createElement('a')
    anchor.appendChild(document.createElement('img'))
    anchor.setAttribute('target', "_blank")
    anchor.setAttribute('rel', "noreferrer")
    anchor.setAttribute('href', uri)
    anchor.click()

    //
    //  +:logging:
    APP_CORE.log.send({ arid: arid }, 'logClickProductPage')
  }

  const getPriceText = price => {
    if (config) {
      if (config.core) {
        //
        //  RDC Price from their feed does not relfect their actual product page price, we need to update it here
        if (config.core.uuid === "530125fe-e217-40ff-93ae-7364c5f089ec") {
          const floatPrice = parseFloat(price);
          const formattedPrice = floatPrice.toFixed(2).replace(".", ",");
          price = formattedPrice;
        }

        const PRICE_STRUCTURE_MAP = {
          en: `${config.core.currency}${price}`,
          ie: `${config.core.currency}${price}`,
          fr: `${price} ${config.core.currency}`
        }
        return PRICE_STRUCTURE_MAP[config.core.language]
      }
    }
  }

  //
  //  TODO: The last remaining bug here is to fix ensure that a new search result is run when navigating from
  //  another page within the app.
  //

  //
  //  :rendering:
  //  Return our JSX for this component.
  const renderSearchResults = () => {
    if (displayMode === 'mobile') {
      return SEARCH_STATE.products.display.map(product => {
        return (
          <div
            key={product.sku}
            className='h-auto w-auto bg-white flex flex-col border-2 rounded-lg mb-[1rem] p-[1rem]'
          >
            <div>
              <h1 onClick={() => {openProductPage(product['add-to-cart-url'], product.arid)}}>{product.name}</h1>
            </div>
            <div className='flex p-1 justify-center mt-4 gap-2 md:gap-8'>
              <div>
                <img
                  className='h-auto max-h-[14rem]'
                  src={product.image}
                  alt=''
                  onClick={() => {openProductPage(product['add-to-cart-url'], product.arid)}}
                />
              </div>
              <div className='flex flex-col mt-6'>
                <h1 className='text-lg text-center'>{getPriceText(product.price)}</h1>
                <div className='w-auto mt-6'>
                  <AllInOneSlimV2
                    arid={product.arid}
                    buttonType={'hover'}
                    product={product}
                    primaryBrandColour={primaryBrandColour}
                    config={config}
                  ></AllInOneSlimV2>
                </div>
                <div className='w-auto mt-2'>
                  <AddToCart config={config} primaryBrandColour={primaryBrandColour} uri={product['add-to-cart-url']} arid={product.arid}></AddToCart>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }
    if (displayMode === 'desktop') {
      return SEARCH_STATE.products.display.map(product => {
        return (
          <div
            key={product.sku}
            className='h-auto w-auto bg-white flex-col mb-[1rem] rounded-lg border-2 p-[0.5rem] lg:p-[1.5rem]'
          >
            <div className='flex gap-2 justify-evenly'>
              <div>
                <img
                  className='h-auto max-h-[12rem] cursor-pointer'
                  src={product.image}
                  alt=''
                  onClick={() => {openProductPage(product['add-to-cart-url'], product.arid)}}
                />
              </div>
              <div className='w-[12rem] flex flex-col ml-6'>
                <div className=''>
                  <h1 className='max-w-prose cursor-pointer' onClick={() => {openProductPage(product['add-to-cart-url'], product.arid)}}>{product.name}</h1>
                </div>
                <div className='w-auto mt-[2rem]'>
                  <AddToCart config={config} primaryBrandColour={primaryBrandColour} uri={product['add-to-cart-url']} arid={product.arid}></AddToCart>
                </div>
              </div>
              <div className='ml-[3rem]'>
                <h1 className='text-lg cursor-pointer' onClick={() => {openProductPage(product['add-to-cart-url'], product.arid)}}>{getPriceText(product.price)}</h1>
              </div>
              <SearchResultsQR
                QRType={'model'}
                arid={product.arid}
                env={'prod'}
                primaryBrandColour={primaryBrandColour}
                config={config}
              ></SearchResultsQR>
              {/* <div className='w-[14rem] bg-[#4c13a2] rounded-lg flex justify-center items-center p-4'>
            <img src={getModelDeliveryURL(product.arid)} alt="" />
          </div> */}
            </div>
          </div>
        )
      })
    }
  }

  return <div className='flex-1'>{renderSearchResults()}</div>
}
