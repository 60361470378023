/*

  s h o w c a s e  s l i c e
  Showcase Slice

  :description:
  Our redux reducer for the showcase feature.

*/

//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import {
  INITIAL_STATE_SEARCH,
  renderInitialStateSearch,
  renderInitialStateSearchFilters
} from '../statics'
import {
  filterIsActive,
  readBrandFilterOptionsFromProducts,
  readCategoryFilterOptionsFromProducts,
  readPriceWindowFilterOptionsFromProducts,
  renderHashForSearchState,
  stringIncludes,
  upsertGetArgumentValuesToState
} from './showcaseAPI'
import { loadConfigFromCloud, loadFromCloudSafe } from '../loader'

//
//  :state:
//  Our main redux state for this feature.

const initialState = {
  //
  //  :products:
  // products: PRODUCTS,
  //
  //  :runtime:
  //  State variables that we are using at runtime.
  isLoading: true,
  loadFromCloud: {
    requesting: false,
    error: null,
    response: null,
    status: null
  },
  loadConfigFromCloud: {
    requesting: false,
    error: null,
    response: null,
    status: null
  },
  products: [],
  config: {},
  //
  //  :v3:
  //  Redux powering React State, removing URL serialisation entirely.
  search: INITIAL_STATE_SEARCH
}

export const loadFromCloudAsync = createAsyncThunk(
  'showcase/loadFromCloud',
  async args => {
    const response = await loadFromCloudSafe(args.language, args.uuid)
    return response.data
  }
)

export const loadConfigFromCloudAsync = createAsyncThunk(
  'showcase/loadConfigFromCloud',
  async args => {
    const response = await loadConfigFromCloud(args.language, args.uuid)
    return response.data
  }
)

export const showcaseSlice = createSlice({
  name: 'showcase',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    //
    //  :v3-filtering:
    //  Update with new approach to filtering our search results.
    initialiseSearchWithProducts: (state, action) => {
      //
      //  :step 1:
      //  We need to reset our filtering state.
      state.search = renderInitialStateSearch()

      //
      //  :step 2:
      //  Upsert from our get arguments.
      upsertGetArgumentValuesToState(state)

      //
      //  :step 3:
      //  In our early edition we sadly can't use the GET args statefully, we'll need to strip whatever args
      //  that are there before continuing.
      //  TODO: Figure this out, it's difficult from here without React tooling.
      /*
      searchParams.delete("q")
      searchParams.delete("b")
      searchParams.delete("c")
      */

      //
      //  :step 2:
      //  We need to take the products from the caller and add them into our search state.
      state.search.products.all = [...action.payload]
      state.search.products.display = [...action.payload]

      //
      //  :step 3:
      //  Now we need to load all of our filters from the data of all products.
      //  We can have initialised values for our filters here from GET arguments.
      if (!filterIsActive(state.search.filters.categories)) {
        state.search.filters.categories = readCategoryFilterOptionsFromProducts(
          state.search.products.display
        )
      }
      if (!filterIsActive(state.search.filters.brands)) {
        state.search.filters.brands = readBrandFilterOptionsFromProducts(
          state.search.products.display
        )
      }

      //
      //  :step 3:
      //  Mark that our filtering is now initialised.
      state.search.initialised = true

      //
      //  :step 4:
      //  Update our hash for the "active search".
      state.search.hash = renderHashForSearchState(state.search)
    },
    updateFromGetArguments: state => {
      upsertGetArgumentValuesToState(state)
    },
    updateSearchHash: state => {
      //
      //  :step 1:
      //  Update our hash for the "active search".
      state.search.hash = renderHashForSearchState(state.search)
    },
    /**
     * Our primary function that "filters" and "sorts" the products for display according to the current redux state.
     * Note, will not auto update, must be called by an external caller each time something changes in our filtering
     * state.
     * @param {Object} state Redux state for this reducer.
     */
    updateSearchProductsDisplay: state => {
      //
      //  :step 0:
      //  Note, the order we apply our rules in matters, this is because we "reduce" some options with each filter
      //  change, we need to handle this by deriving the order to apply our filters in.
      //  Arrayify filter status & apply via a dynamic map of filter functions.
      //

      //
      //  :step 1:
      //  Looking at the products.all list, derive the list of products to "display".
      let products = []

      const FILTER_VERSION = 2

      if (FILTER_VERSION === 2) {
        //
        //  :step 0:
        //  Create a place to store all of our results.
        const results = {
          text: [],
          brand: [],
          category: [],
          priceWindow: []
        }

        //
        //  :step 1a:
        //  Filter everything down by search text first of all.
        state.search.products.all.map(product => {
          if (
            stringIncludes(product.brand, state.search.filters.text) ||
            stringIncludes(product.name, state.search.filters.text)
          ) {
            results.text.push(product)
          }
          return true
        })

        //
        //  :step 1b:
        //  Ok, handle applying the brand filters.
        if (filterIsActive(state.search.filters.brands)) {
          //
          //  Only add products to the output if they exactly match our selected brands.
          results.brand = state.search.products.all.filter(product => {
            for (const [brand, enabled] of Object.entries(
              state.search.filters.brands
            )) {
              if (product.brand === brand && enabled) {
                return true
              }
            }
            return false
          })
        } else {
          //
          //  We should only show the brand filters for brands that apply to our results.
          //state.search.filters.brands = readBrandFilterOptionsFromProducts(
          //  state.search.products.all
          //)
          //
          //  Update to all products match.
          results.brand = state.search.products.all
        }

        //
        //  :step 1c:
        //  Ok, handle applying the category filters.
        if (filterIsActive(state.search.filters.categories)) {
          //
          //  Only add products to the output if they exactly match our selected categories.
          results.category = state.search.products.all.filter(product => {
            for (const [category, enabled] of Object.entries(
              state.search.filters.categories
            )) {
              if (product.category === category && enabled) {
                return true
              }
            }
            return false
          })
        } else {
          //
          //  We should only show the category filters for categories that apply to our results.
          //state.search.filters.categories =
          //  readCategoryFilterOptionsFromProducts(state.search.products.all)
          //
          //  Upsert all products as they match with no filters.
          results.category = state.search.products.all
        }

        //
        //  :step 1d:
        //  Ok, handle applying the priceWindow filters.
        if (filterIsActive(state.search.filters.prices, 'active')) {
          //
          //  Only add products to the output if their price is within the active price windows.
          results.priceWindow = state.search.products.all.filter(product => {
            let inActivePriceWindow = false
            // eslint-disable-next-line
            for (const [priceWindow, priceWindowConfig] of Object.entries(
              state.search.filters.prices
            )) {
              if (
                product.price >= priceWindowConfig.from &&
                product.price <= priceWindowConfig.to &&
                priceWindowConfig.active
              ) {
                inActivePriceWindow = true
              }
            }
            return inActivePriceWindow
          })
        } else {
          //
          //  Reset our prices filter.
          //state.search.filters.prices = renderInitialStateSearchFilters().prices
          //
          //  Upsert that all products match.
          results.priceWindow = state.search.products.all
        }

        //
        //  :step 4:
        //  We need to collapse all the results into ones that match in every filter.
        const SKU_TO_MATCHES = {}
        const allResults = [
          ...results.text,
          ...results.brand,
          ...results.category,
          ...results.priceWindow
        ]
        allResults.map(product => {
          if (typeof SKU_TO_MATCHES[product.sku] === 'undefined') {
            SKU_TO_MATCHES[product.sku] = 0
          }
          SKU_TO_MATCHES[product.sku] += 1
          return true
        })
        for (const [sku, matches] of Object.entries(SKU_TO_MATCHES)) {
          if (matches === Object.keys(results).length) {
            // eslint-disable-next-line
            state.search.products.all.map(prod => {
              if (prod.sku === sku) {
                products.push(prod)
              }
              return true
            })
          }
        }

        //
        //  :step 5:
        //  Boolean logic is difficult here as all filters are "equal" in priority.
        //  We don't need to worry about text, but the other filters have a state that reflects the products
        //  we have filtered down.
        //
        //  When the user has not engaged, we display all filtering options
        //  When the user has engaged with a filter, we display only the selected filter and reduce the other filter
        //  options according to the now "actively filtered" results.

        const ACTIVE_FILTERS = {
          category: filterIsActive(state.search.filters.categories),
          brand: filterIsActive(state.search.filters.brands),
          prices: filterIsActive(state.search.filters.prices, 'active')
        }
        const A_FILTER_IS_ACTIVE =
          ACTIVE_FILTERS.category ||
          ACTIVE_FILTERS.brand ||
          ACTIVE_FILTERS.prices

        //
        //  Now, for each filter type, handle updating the filter options.
        if (ACTIVE_FILTERS.category) {
          state.search.filters.categories = {
            ...readCategoryFilterOptionsFromProducts(
              products,
              state.search.filters.categories
            )
          }
        } else {
          state.search.filters.categories =
            readCategoryFilterOptionsFromProducts(
              A_FILTER_IS_ACTIVE ? products : state.search.products.all
            )
        }
        if (ACTIVE_FILTERS.brand) {
          state.search.filters.brands = {
            ...readBrandFilterOptionsFromProducts(
              products,
              state.search.filters.brands
            )
          }
        } else {
          state.search.filters.brands = readBrandFilterOptionsFromProducts(
            A_FILTER_IS_ACTIVE ? products : state.search.products.all
          )
        }
        if (ACTIVE_FILTERS.prices) {
          state.search.filters.prices = {
            ...readPriceWindowFilterOptionsFromProducts(
              products,
              state.search.filters.prices,
              renderInitialStateSearchFilters().prices
            )
          }
        } else {
          state.search.filters.prices =
            readPriceWindowFilterOptionsFromProducts(
              A_FILTER_IS_ACTIVE ? products : state.search.products.all,
              null,
              renderInitialStateSearchFilters().prices
            )
        }

        //
        //  :step 5:
        //  Now that we know that we have our actual results, build our filters from there.

        /*
        const aFilterIsActive =
          filterIsActive(state.search.filters.categories) &&
          filterIsActive(state.search.filters.brands) &&
          filterIsActive(state.search.filters.prices, 'active')
        if (aFilterIsActive) {
          state.search.filters.categories = {
            ...readCategoryFilterOptionsFromProducts(
              products,
              state.search.filters.categories
            )
          }
        }
        if (aFilterIsActive) {
          state.search.filters.brands = {
            ...readBrandFilterOptionsFromProducts(
              products,
              state.search.filters.brands
            )
          }
        }
        if (aFilterIsActive) {
          state.search.filters.prices = renderInitialStateSearchFilters().prices
        }
        */
        //
        //  Remove non matching results.
      }

      if (FILTER_VERSION === 1) {
        //
        //  :step 1a:
        //  Filter everything down by search text first of all.
        state.search.products.all.map(product => {
          if (
            stringIncludes(product.brand, state.search.filters.text) ||
            stringIncludes(product.name, state.search.filters.text)
          ) {
            products.push(product)
          }
          return true
        })

        //
        //  :step 1b:
        //  Ok, handle applying the brand filters.
        if (filterIsActive(state.search.filters.brands)) {
          //
          //  Only add products to the output if they exactly match our selected brands.
          products = products.filter(product => {
            for (const [brand, enabled] of Object.entries(
              state.search.filters.brands
            )) {
              if (product.brand === brand && enabled) {
                return true
              }
            }
            return false
          })
        } else {
          //
          //  We should only show the brand filters for brands that apply to our results.
          state.search.filters.brands =
            readBrandFilterOptionsFromProducts(products)
        }

        //
        //  :step 1c:
        //  Ok, handle applying the category filters.
        if (filterIsActive(state.search.filters.categories)) {
          //
          //  Only add products to the output if they exactly match our selected categories.
          products = products.filter(product => {
            for (const [category, enabled] of Object.entries(
              state.search.filters.categories
            )) {
              if (product.category === category && enabled) {
                return true
              }
            }
            return false
          })
        } else {
          //
          //  We should only show the category filters for categories that apply to our results.
          state.search.filters.categories =
            readCategoryFilterOptionsFromProducts(products)
        }

        //
        //  :step 1d:
        //  Ok, handle applying the priceWindow filters.
        if (filterIsActive(state.search.filters.prices, 'active')) {
          //
          //  Only add products to the output if their price is within the active price windows.
          products = products.filter(product => {
            let inActivePriceWindow = false
            // eslint-disable-next-line
            for (const [priceWindow, priceWindowConfig] of Object.entries(
              state.search.filters.prices
            )) {
              if (
                product.price >= priceWindowConfig.from &&
                product.price <= priceWindowConfig.to &&
                priceWindowConfig.active
              ) {
                inActivePriceWindow = true
              }
            }
            return inActivePriceWindow
          })
        } else {
          //
          //  Reset our prices filter.
          state.search.filters.prices = renderInitialStateSearchFilters().prices
        }

        //
        //  We should only show the price windows that apply to our products.
        //  Note, this is 2n here, but can't be avoided without copying our prices state.
        const priceWindowsWithNoProducts = []
        for (const [priceWindow, priceWindowConfig] of Object.entries(
          state.search.filters.prices
        )) {
          let productsWithinPriceWindow = 0
          products.map(product => {
            if (
              product.price >= priceWindowConfig.from &&
              product.price <= priceWindowConfig.to
            ) {
              productsWithinPriceWindow += 1
            }
            return true
          })
          if (productsWithinPriceWindow === 0) {
            priceWindowsWithNoProducts.push(priceWindow)
          }
        }
        priceWindowsWithNoProducts.map(priceWindow => {
          delete state.search.filters.prices[priceWindow]
          return true
        })
      }

      //
      //  :step 2:
      //  With the list of products to display ready, sort our results.
      if (state.search.ordering.key) {
        const isAscending = state.search.ordering.direction >= 0
        products = products.sort((a, b) => {
          if (state.search.ordering.key === 'price') {
            if (isAscending) {
              return a.price - b.price
            } else {
              return b.price - a.price
            }
          }
          if (state.search.ordering.key === 'brand') {
            const aValue = a.brand.toLowerCase()
            const bValue = b.brand.toLowerCase()
            if (isAscending) {
              if (aValue.charCodeAt(0) > bValue.charCodeAt(0)) {
                return 1
              }
              if (aValue.charCodeAt(0) < bValue.charCodeAt(0)) {
                return -1
              }
            } else {
              if (bValue.charCodeAt(0) < aValue.charCodeAt(0)) {
                return -1
              }
              if (bValue.charCodeAt(0) > aValue.charCodeAt(0)) {
                return 1
              }
            }
          }
          return 0
        })
      }

      //
      //  :step 3:
      //  With the sorted list of products to display, we can now set our pages config.
      const searchText = state.search.filters.text
      const pageSize = state.search.pages.size
      const totalProducts = products.length
      const totalPages = Math.ceil(totalProducts / state.search.pages.size)
      state.search.pages.total = totalPages
      //
      //  If the total pages are greater than our current page, reset.
      if (
        totalPages < state.search.pages.current ||
        state.search.pages.current === 0
      ) {
        state.search.pages.current = 1
      }

      //
      //  :step 4:
      //  Update the text in our state based on these new results.
      //
      //  Handle text for showing less than page size or for all products.
      if (pageSize < totalProducts) {
        state.search.text.heading = `${pageSize}+ results for '${searchText}'`
      }
      if (pageSize > totalProducts) {
        state.search.text.heading = `${totalProducts} results for '${searchText}'`
      }
      //
      //  If we are set to show all, update the heading to reflect this.
      if (state.search.pages.size > 1000) {
        state.search.text.heading = `Showing all '${totalProducts}' results`
      }
      if (state.search.text.heading.includes(`for ''`)) {
        state.search.text.heading = state.search.text.heading.replace(
          " for ''",
          ''
        )
      }

      const firstVisibleProductIndex =
        (state.search.pages.current - 1) * pageSize + 1
      const lastVisibleProductIndex = Math.min(
        state.search.pages.current * pageSize,
        totalProducts
      )
      state.search.text.showing = `Showing ${firstVisibleProductIndex} - ${lastVisibleProductIndex} of ${totalProducts}`

      //
      //  :step 5:
      //  We can slice down our products so that we only display the ones for the current page.
      products = products.slice(
        (state.search.pages.current - 1) * pageSize,
        state.search.pages.current * pageSize
      )

      //
      //  :step 6:
      //  Set our "display" products in state, update any other matching state attributes.
      state.search.products.display = products

      //
      //  :step 7:
      //  Update our hash for the "active search".
      state.search.hash = renderHashForSearchState(state.search)
    },
    updateSearchFilteringText: (state, action) => {
      //
      //
      state.search.filters.text = action.payload
    },
    updateSearchFilteringEnableBrand: (state, action) => {
      //
      //  :step 1:
      //  Enable the given brand, but only if it already exists as a filter.
      if (typeof state.search.filters.brands[action.payload] !== 'undefined') {
        state.search.filters.brands[action.payload] = true
      }
    },
    updateSearchFilteringDisableBrand: (state, action) => {
      //
      //  :step 1:
      //  Disable the given brand, but only if it already exists as a filter.
      if (typeof state.search.filters.brands[action.payload] !== 'undefined') {
        state.search.filters.brands[action.payload] = false
      }
    },
    updateSearchFilteringEnableCategory: (state, action) => {
      //
      //  :step 1:
      //  Enable the given category, but only if it already exists as a filter.
      if (
        typeof state.search.filters.categories[action.payload] !== 'undefined'
      ) {
        state.search.filters.categories[action.payload] = true
      }
    },
    updateSearchFilteringDisableCategory: (state, action) => {
      //
      //  :step 1:
      //  Disable the given category, but only if it already exists as a filter.
      if (
        typeof state.search.filters.categories[action.payload] !== 'undefined'
      ) {
        state.search.filters.categories[action.payload] = false
      }
    },
    updateSearchFilteringEnablePriceWindow: (state, action) => {
      //
      //  :step 1:
      //  Enable the given priceWindow, but only if it already exists as a filter.
      if (typeof state.search.filters.prices[action.payload] !== 'undefined') {
        state.search.filters.prices[action.payload].active = true
      }
    },
    updateSearchFilteringDisablePriceWindow: (state, action) => {
      //
      //  :step 1:
      //  Disable the given priceWindow, but only if it already exists as a filter.
      if (typeof state.search.filters.prices[action.payload] !== 'undefined') {
        state.search.filters.prices[action.payload].active = false
      }
    },
    updateSearchOrderingKey: (state, action) => {
      if (!action.payload) {
        state.search.ordering.key = null
        state.search.ordering.direction = 1
      } else {
        state.search.ordering.key = action.payload
      }
    },
    updateSearchOrderingDirection: (state, action) => {
      state.search.ordering.direction = action.payload
    },
    updateSearchOrderingDirectionAscending: state => {
      state.search.ordering.direction = 1
    },
    updateSearchOrderingDirectionDescending: state => {
      state.search.ordering.direction = -1
    },
    updateSearchProductsPerPage: (state, action) => {
      state.search.pages.size = action.payload
    },
    updateSearchCurrentPage: (state, action) => {
      state.search.pages.current = action.payload
    },
    wipeBrandAndCategoryFilters: state => {
      state.search.filters.brands = {}
      state.search.filters.categories = {}
    },
    uninitialiseState: state => {
      state.search.initialised = false
    },
    wipeSearchtext: state => {
      state.search.filters.text = ''
    }
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.

  extraReducers: builder => {
    builder
      //
      // products
      .addCase(loadFromCloudAsync.pending, state => {
        state.loadFromCloud.error = null
        state.loadFromCloud.requesting = true
        state.loadFromCloud.response = null
        state.loadFromCloud.status = 'requesting'
      })
      .addCase(loadFromCloudAsync.fulfilled, (state, action) => {
        state.loadFromCloud.error = null
        state.loadFromCloud.requesting = false
        state.loadFromCloud.response = action.payload
        state.loadFromCloud.status = 'idle'
        //
        //  Upsert our products to state.
        state.products = action.payload || []
      })
      .addCase(loadFromCloudAsync.rejected, (state, action) => {
        state.loadFromCloud.error = true
        state.loadFromCloud.requesting = false
        state.loadFromCloud.status = 'errored'
        state.loadFromCloud.response = action.payload
      })
      //
      // config
      .addCase(loadConfigFromCloudAsync.pending, state => {
        state.loadConfigFromCloud.error = null
        state.loadConfigFromCloud.requesting = true
        state.loadConfigFromCloud.response = null
        state.loadConfigFromCloud.status = 'requesting'
      })
      .addCase(loadConfigFromCloudAsync.fulfilled, (state, action) => {
        state.loadConfigFromCloud.error = null
        state.loadConfigFromCloud.requesting = false
        state.loadConfigFromCloud.response = action.payload
        state.loadConfigFromCloud.status = 'idle'
        //
        //  Upsert our config to state.
        state.config = action.payload || {}
      })
      .addCase(loadConfigFromCloudAsync.rejected, (state, action) => {
        state.loadConfigFromCloud.error = true
        state.loadConfigFromCloud.requesting = false
        state.loadConfigFromCloud.status = 'errored'
        state.loadConfigFromCloud.response = action.payload
      })
  }
})

//
//  :api:
export const renderHashOfSearchState = renderHashForSearchState

//
//  :reducers:
//  Export any reducers from here.
export const {
  initialiseSearchWithProducts,
  updateSearchProductsDisplay,
  updateSearchFilteringText,

  updateSearchFilteringEnableBrand,
  updateSearchFilteringDisableBrand,
  updateSearchFilteringEnableCategory,
  updateSearchFilteringDisableCategory,
  updateSearchFilteringEnablePriceWindow,
  updateSearchFilteringDisablePriceWindow,

  updateSearchOrderingKey,
  updateSearchOrderingDirection,
  updateSearchOrderingDirectionAscending,
  updateSearchOrderingDirectionDescending,
  updateSearchProductsPerPage,
  updateSearchCurrentPage,
  updateFromGetArguments,
  wipeBrandAndCategoryFilters,
  uninitialiseState,
  wipeSearchtext
} = showcaseSlice.actions

// export const selectProducts = (state) => state.showcase.products
export const selectLoadFromCloud = state => state.showcase.loadFromCloud
export const selectProducts = state => state.showcase.products
export const selectConfigLoadFromCloud = state =>
  state.showcase.loadConfigFromCloud
export const selectConfig = state => state.showcase.config

export const selectSearchFiltering = state => state.showcase.filtering.search

//
//  :v3-filtering:
//  An upgrade to export our entire search entry from state.
export const selectSearchState = state => state.showcase.search

//
//  :reducer:
export default showcaseSlice.reducer
