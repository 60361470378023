/*

  a p p  c o r e  s l i c e
  AppCore Slice
  
  :description:
  Our primary redux reducer for AppCore functionality.

  We can import the memory singleton APP_CORE and use it anywhere in the app, but we should only configure it from
  this file.

*/

//
//  :react & redux:
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

//
//  :code:
import { APP_CORE } from './memory'
import { ARShowcaseEventLogging } from './ARShowcaseEventLogging'

//
//  :state:
//  Our main redux state for this feature.
const initialState = {
  isConfigured: false,
  initialiseAppCore: {
    loading: false,
    error: false,
    response: false,
    status: null,
    ready: false,
  },
  loggingIsConfigured: false,
}

//
//  :thunks:
//  We only really have 1x async thunk here.

export const initialiseAppCoreAsync = createAsyncThunk('app-core/initialise-app-core', async payload => {
  const response = await APP_CORE.go()
  return response.data
})

export const appCoreSlice = createSlice({
  name: 'app-core',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    configureAppCore(state) {
      //
      //  :step 1:
      //  Configure all of the HTTP Get arguments that the app supports.

      //
      //  :step 2:
      //  Configure our options.
      APP_CORE.setOptionValue('scanForAR', false)
      APP_CORE.setOptionValue('localisation', {
        app: 'app-ar-showcase',
        version: 'latest',
      })

      //
      //  :step 3:
      //  Mark that we are now configured.
      state.isConfigured = true
    },
    configureLogging(state, action) {
      //
      //  :step 0:
      //  Correct the language.
      let language = APP_CORE.readBrowserConfig('language') || 'en'
      if (language) {
        language = language.split('-')[0]
      }

      //
      //  :step 1:
      //  upset language from url

      //
      //  :step 2:
      //  Set up our logging.
      if (!state.loggingIsConfigured) {
        APP_CORE.log.activeMode = 'custom' // TODO: Fix this bug, shouldn't need to set it.
        APP_CORE.initialiseLoggingLibraryRuntime(
          'custom',
          {
            distributor: action.payload.distributor || null,
            language: action.payload.language || null,
            environment: action.payload.environment || null,
            location: action.payload.location || null,
            session: action.payload.session || null,
            user: action.payload.user || null,
            memoryOnly: true,
          },
          ARShowcaseEventLogging
        )
        if (action.payload.distributor && action.payload.language && action.payload.user) {
          state.loggingIsConfigured = true
          APP_CORE.log._enable()
        }
      }
    },
  },
  extraReducers: builder => {
    builder
      //
      //  :save-to-cloud:
      .addCase(initialiseAppCoreAsync.pending, state => {
        state.initialiseAppCore.error = null
        state.initialiseAppCore.loading = true
        state.initialiseAppCore.response = null
        state.initialiseAppCore.status = 'loading'
      })
      .addCase(initialiseAppCoreAsync.fulfilled, (state, action) => {
        state.initialiseAppCore.error = null
        state.initialiseAppCore.loading = false
        state.initialiseAppCore.response = action.payload
        state.initialiseAppCore.status = 'idle'
        state.initialiseAppCore.ready = true
      })
  },
})

//
//  :exports:
//  The named exports for this feature.
export const { configureAppCore, configureLogging } = appCoreSlice.actions

//
//  :selectors:
//  Mechanisms to select from state.
export const selectIsConfigured = state => state.appCore.isConfigured
export const selectLoggingIsConfigured = state => state.appCore.loggingIsConfigured
export const selectInitialiseAppCore = state => state.appCore.initialiseAppCore

//
//  :reducer:
export default appCoreSlice.reducer
