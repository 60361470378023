

//
//  :react & redux:
import React from 'react'
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'

//
//  :code:
import { getLocalisedText } from '../../../js/helpers'
import { LOCALISATION_ID_ENUMS } from '../../../statics'

export const AddToCart = props => {
  const { uri, primaryBrandColour, config, arid } = props
  if (!uri) { return; }

  let addToCartURL = ''
  const knownUnsupportedA2CRetailers = ['530125fe-e217-40ff-93ae-7364c5f089ec', 'all-enabled-retailer']

  if (knownUnsupportedA2CRetailers.includes(config.core.uuid)) {
    addToCartURL = `${uri}?order=eky-a2c`
  } else {
    addToCartURL = `${uri}?eky-a2c`
  }

  const addToCartClickHandler = uri => {
    const anchor = document.createElement('a')
    anchor.appendChild(document.createElement('img'))
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('rel', 'noreferrer')
    anchor.setAttribute('href', uri)
    anchor.click()

    //
    //  +:logging:
    APP_CORE.log.send({ arid: arid }, "logClickAddToCart")
  }

  const getButtonStyles = () => {
    if (config) {
      if (config.core) {
        const LANGUAGE_STYLE_MAPING = {
          ie: 'cursor-pointer rounded-full text-white w-[8rem] h-[2rem] flex justify-center text-center hover:scale-110 transition-all duration-500',
          fr: 'cursor-pointer rounded-full text-white w-[9.5rem] h-[2rem] flex justify-center text-center hover:scale-110 transition-all duration-500',
          en: 'cursor-pointer rounded-full text-white w-[8rem] h-[2rem] flex justify-center text-center hover:scale-110 transition-all duration-500',
        }
        return LANGUAGE_STYLE_MAPING[config.core.language]
      }
    }
  }

  return (
    <>
      <div
        className={getButtonStyles()}
        style={{ backgroundColor: primaryBrandColour }}
        onClick={() => {
          addToCartClickHandler(addToCartURL)
        }}
      >
        <button>
          {getLocalisedText(LOCALISATION_ID_ENUMS.buttonAddToCart, config)}
        </button>
      </div>
    </>
  )
}
