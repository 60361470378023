//
// :enums:
export const CONFIG_ENUMS = {
  sponsoredBanner: "sponsored-banner",
  categories: "categories",
  brands: "brands",
  helpMeChoose: "help-me-choose",
  titlePrefix: "title-prefix",
  discover: "discover",
  enabledBrandStores: "enabled-brand-stores"
}

export const CATEGORY_ENUMS = {
  //
  //  :currys:
  "Washing Machines": "Washing machines",
  "Laptops": "Laptops",
  "TVs": "TVs",
  "Headphones": "Headphones",
  "Gaming": "Gaming laptops",
  "Mobile Phones": "Mobile phones",
  "Coffee Machines": "Coffee machines",
  "Smart watches & fitness trackers": "Smart watches",
  "iPads & tablets": "Tablets",
  "Vacuum cleaners": "Vacuum cleaners",

  //
  //  :rdc:
  "Ordinateurs portables": "Ordinateurs portables",
  "Téléviseurs": "Téléviseurs",
  "Micro casque": "Micro casque",
  "Smartphones": "Smartphones",
  "Cafetière électrique": "Cafetière électrique",
  "Souris": "Souris",
  "Ecrans PC": "Ecrans PC",

  //
  //  :best-buy:
  "Sound Bars": "Sound Bars",
  "Smartwatches": "Smartwatches",
  "Smart Glasses": "Smart Glasses",
  "Gaming Mice": "Gaming Mice",
  "Espresso Machines": "Espresso Machines",
  "2-in-1 Laptops": "2 in 1 Laptops"
}

export const SAMSUNG_CATEGORY_ENUMS = {
  "TVs": "TVs",
  "Cooking": "Cookers",
  "Laundry": "Washer dryers",
  "Mobile Phones": "Mobile phones",
  "Tablets": "Tablets",
  "Wearables": "Smart watches",
  "Audio": "Speakers"
}

export const LOCALISATION_ID_ENUMS = {
  //
  //  :error:
  "errorNotFound": "error-not-found",
  "errorNotFoundBody": "error-not-found-body",
  //
  //  :input:
  "inputPlaceholder": "input-placeholder",
  //
  //  :titles:
  "titleShopByCategory": "title-shop-by-category",
  "titleShopByBrand": "title-shop-by-brand",
  "titlePopularProducts": "title-popular-products",
  //
  //  :expert:
  "expertBannerHelpMeChoose": "expert-banner-help-me-choose",
  "expertBannerHelpMeChooseBody": "expert-banner-help-me-choose-body",
  //
  //  :expert:
  "expertChooseTv": "expert-choose-tv",
  "expertChooseWashingMachine": "expert-choose-washing-machine",
  "expertChooseLaptop": "expert-choose-laptop",
  "expertChooseDishwasher": "expert-choose-dishwasher",
  "expertChooseSoundbar": "expert-choose-soundbar",
  "expertChoosePhone": "expert-choose-phone",
  "expertChooseTablet": "expert-choose-tablet",
  "expertChoosePritner": "expert-choose-pritner",
  "expertChooseCoffeeMachine": "expert-choose-coffee-machine",
  //
  //  :buttons:
  "buttonExpert": "button-expert",
  "buttonPopupTitle": "button-popup-title",
  "buttonSeeInAr": "button-see-in-ar",
  "buttonAddToCart": "button-add-to-cart",
  "buttonBack": "button-back",
  "buttonHover": "button-hover",
  //
  //  :branded:
  "brandedSamsungBannerText": "branded-samsung-banner-text",
  "brandedTitlePopularSamsungProducts": "branded-title-popular-samsung-products",
  "brandedTitleDiscoverSamsung": "branded-title-discover-samsung",
  "buttonDiscoverInAr": "button-discover-in-ar",
  //
  //  :results:
  "resultsTitle": "results-title",
  "resultsTitleSubText": "results-title-sub-text",
  "resultsFiltersTitle": "results-filters-title",
  "resultsSortByRelevance": "results-sort-by-relevance",
  "resultsSortByBrandA": "results-sort-by-brand-a",
  "resultsSortByBrandZ": "results-sort-by-brand-z",
  "resultsSortByPriceLow": "results-sort-by-price-low",
  "resultsSortByPriceHigh": "results-sort-by-price-high",
  "resultsShowing": "results-showing",
  "resultsShowingOf": "results-showing-of",
  "resultsInputPlaceholder": "results-input-placeholder",
  "resultsCategories": "results-categories",
  "resultsByPriceLowHigh": "results-by-price-low-high",
  "resultsByBrand": "results-by-brand",
  "resultsShowing20Products": "results-showing-20-products",
  "resultsShowing40Products": "results-showing-40-products",
  "resultsShowingAllProducts": "results-showing-all-products"
}

//
//  :mapping:
export const RETAILER_STYLE_CLASS_MAPPING = {
  "Currys IE": {
    "normal-button": "",
    "long-button": "",
    "input-container": "",
    "input": "",
    "content": ""
  }
}