/*

  s e a r c h  r e s u l t s  U I  v 3
  SearchResultsUI V3

  :description:
  This file contains the UI for the search results page

*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'

//
//  :code:
import { SponsoredBanner } from '../../components/ui/sponsored-banner/SponsoredBanner'
import Content from '../../components/layout/content/Content'
import { FilterIcon } from '../../components/ui/svgs/FilterIcon'
import { SmartBanner } from '../../components/ui/smart-banner/SmartBanner'
import { PageNavigationUI } from './presentation-ui/page-navigation/PageNavigationUI'
import { PageHeadingUI } from './presentation-ui/page-heading/PageHeadingUI'
import { PageSizeUI } from './presentation-ui/page-size/PageSizeUI'
import { ResultsSortingUI } from './presentation-ui/results-sorting/ResultsSortingUI'
import { PriceWindowsUI } from './filter-ui/price-windows/PriceWindowsFilterUI'
import { CategoriesFilterUI } from './filter-ui/categories/CategoriesFilterUI'
import { BrandsFilterUI } from './filter-ui/brands/BrandsFilterUI'
import { SearchResults } from './presentation-ui/search-results/SearchResults'
import { SearchboxUI } from './presentation-ui/searchbox/SearchboxUI'
import { Back } from '../../components/ui/buttons/Back'
import { getLocalisedText, isSectionEnabled } from '../../js/helpers'
import { LOCALISATION_ID_ENUMS } from '../../statics'
import { HelpMeChooseCards } from '../../components/ui/help-me-choose-cards/HelpMeChooseCards'

export const SearchResultsUIv3 = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config, products, page, displayMode, primaryBrandColour, styling } = props

  //
  //  :react:
  //  We'll need some react state too to handle inputs.
  const [showFiltersMenu, setShowFiltersMenu] = useState(false)

  //
  //  UseEffect for updating the container height of our parent container
  useEffect(() => {
    if (window.parent) {
      if (config.core.resizeContainer) {
        setTimeout(() => {
          window.parent.postMessage({ scrollHeight: document.body.scrollHeight, action: 'update-showcase-embed-height' }, '*')
        }, 600)
      }
    }
  }, [config])

  const renderFiltersUI = () => {
    // xl:sticky top-4
    return (
      <div
        className={`flex flex-col h-fit w-[15rem] lg:w-[18rem] xl:w-[22rem] bg-white rounded-md absolute lg:relative ${
          displayMode === 'mobile' ? 'absolute' : ''
        } z-10 border-2 mt-10 md:mt-0 md:right-0`}
      >
        <div className="flex p-4 relative">
          <h1 className="text-2xl">{getLocalisedText(LOCALISATION_ID_ENUMS.resultsFiltersTitle, config)}</h1>
          <button
            className={`absolute top-0 right-0 m-2 cursor-pointer text-sm drop-shadow ${
              displayMode === 'mobile' ? '' : 'hidden'
            }`}
            onClick={() => {
              setShowFiltersMenu(!showFiltersMenu)
            }}
          >
            Close X
          </button>
        </div>

        <div className="w-full border-b-2"></div>

        <div className="flex flex-col p-4">
          <div className="my-[1rem]">
            <h1 className="text-lg font-medium mb-2">
              {getLocalisedText(LOCALISATION_ID_ENUMS.resultsCategories, config)}
            </h1>
            <CategoriesFilterUI></CategoriesFilterUI>
          </div>
        </div>

        <div className="w-full border-b-2"></div>

        <div className="flex flex-col p-4">
          <div className="my-[1rem]">
            <h1 className="text-lg font-medium mb-2">
              {getLocalisedText(LOCALISATION_ID_ENUMS.resultsByPriceLowHigh, config)}
            </h1>
            <PriceWindowsUI></PriceWindowsUI>
          </div>
        </div>

        <div className="w-full border-b-2"></div>

        <div className="flex flex-col p-4">
          <div className="my-[1rem]">
            <h1 className="text-lg font-medium mb-2">
              {getLocalisedText(LOCALISATION_ID_ENUMS.resultsByBrand, config)}
            </h1>
            <BrandsFilterUI></BrandsFilterUI>
          </div>
        </div>
      </div>
    )
  }

  const renderSearchResults = () => {
    return (
      <div className="flex flex-col">
        <SearchResults products={products} displayMode={displayMode} config={config} styling={styling}></SearchResults>
        <PageNavigationUI primaryBrandColour={primaryBrandColour}></PageNavigationUI>
      </div>
    )
  }

  const renderDesktop = () => {
    return (
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col items-center">
          <div className="w-4/5 flex flex-col 2xl:max-w-[1700px] relative my-2 ">
            <div className="flex flex-col mt-8 items-center">
              <SearchboxUI primaryBrandColour={primaryBrandColour}></SearchboxUI>
              <PageHeadingUI primaryBrandColour={primaryBrandColour}></PageHeadingUI>
            </div>
            <Back classes={'2xl:max-w-[1700px]'} primaryBrandColour={primaryBrandColour} config={config}></Back>
          </div>
          <div className="w-full flex justify-center pt-8 bg-[#f5f4f4]">
            <div className="flex justify-center pt-8 bg-[#f5f4f4]">
              <div className="mr-4">{renderFiltersUI()}</div>
              <div className="flex-col w-[720px] xl:w-[900px]">
                <div className="mb-4 flex items-center">
                  <ResultsSortingUI></ResultsSortingUI>
                  <PageSizeUI></PageSizeUI>
                  <span className="hidden">products per page</span>
                </div>
                {renderSearchResults()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderMobile = () => {
    return (
      <div className="w-full flex flex-col bg-[#f5f4f4] my-4 pt-2">
        <div className="w-full flex flex-col items-center">
          <div className="w-4/5 relative ">
            <div className="absolute top-0 left-1 m-2 md:m-4 cursor-pointer">
              <Back buttonText={'< Back'} primaryBrandColour={primaryBrandColour} config={config}></Back>
            </div>
            <div className="flex flex-col mt-8 items-center">
              <PageHeadingUI primaryBrandColour={primaryBrandColour}></PageHeadingUI>
              <SearchboxUI primaryBrandColour={primaryBrandColour}></SearchboxUI>

              <div className="flex gap-2 mb-8 relative">
                <div className="hidden">
                  <PageSizeUI></PageSizeUI>
                </div>
                <div className="flex flex-col sm:flex-row items-center gap-2">
                  <ResultsSortingUI></ResultsSortingUI>
                  <button
                    className="p-1 w-[10rem] h-[2.3rem] bg-white border border-2 rounded-lg flex justify-center items-center"
                    onClick={() => {
                      setShowFiltersMenu(!showFiltersMenu)
                    }}
                  >
                    <FilterIcon></FilterIcon>
                    <p>Filters</p>
                  </button>
                </div>

                {showFiltersMenu && renderFiltersUI()}
              </div>
            </div>

            {renderSearchResults()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Content>
      {isSectionEnabled('sponsored-banner', page, config) && (
        <SponsoredBanner
          styles={'mt-[1rem] cursor-pointer'}
          displayMode={displayMode}
          config={config}
          retailer={config.core.name}
          currentPage={'landing'}
        ></SponsoredBanner>
      )}
      <div id="search-results"></div>

      {displayMode === 'mobile' && renderMobile()}

      {displayMode === 'desktop' && renderDesktop()}

      <div className="my-8"></div>
      {isSectionEnabled('expert-banner', page, config) && (
        <SmartBanner group={'expert'} distributor={distributor} language={language} config={config}></SmartBanner>
      )}
      {isSectionEnabled('how-to-choose-cards', page, config) && (
        <HelpMeChooseCards
          primaryBrandColour={primaryBrandColour}
          displayMode={displayMode}
          config={config}
        ></HelpMeChooseCards>
      )}
      <div className="my-8"></div>
    </Content>
  )
}
