/*





*/

//
//  :react & redux:
import { useState } from 'react'
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'

//
//  :code:
import { getAvatarDeliveryURL, getModelDeliveryURL } from '../../../js/utils'
import { AugmentedRealityIcon } from '../svgs/AugmentedRealityIcon'
import { getLocalisedText } from '../../../js/helpers'

//
//  :statics
import { LOCALISATION_ID_ENUMS } from '../../../statics'

//
//  :render:
export const SearchResultsQR = props => {
  const [isHovering, setIsHovering] = useState(false)

  const { QRType, arid, env, primaryBrandColour, config } = props
  if (!QRType || !arid || !env) {
    return
  }

  const TYPE_MAPPING = {
    model: getModelDeliveryURL,
    avatar: getAvatarDeliveryURL
  }

  const QRsrc = TYPE_MAPPING[QRType](arid, env)

  const handleButtonHoverStart = () => {
    setIsHovering(true)
    //
    //  +:logging:
    APP_CORE.log.send({ arid: arid }, 'logClickARModelButton')
  }
  const handleButtonHoverEnd = () => {
    setTimeout(() => {
      setIsHovering(false)
    }, 125)
  }

  const getQRStyles = () => {
    if (isHovering) {
      return 'search-results-qr-visible'
    } else {
      return 'search-results-qr-hidden'
    }
  }

  return (
    <>
      <div
        style={{ backgroundColor: primaryBrandColour }}
        className={`w-[12rem] h-[12rem] m-4 rounded-lg flex flex-col justify-center items-center p-3`}
        onMouseEnter={() => {
          handleButtonHoverStart()
        }}
        onMouseLeave={() => {
          handleButtonHoverEnd()
        }}
      >
        {!isHovering && (
          <>
            <div>
              <AugmentedRealityIcon
                viewbox={'-3 1 30 25'}
                width={150}
                height={90}
              ></AugmentedRealityIcon>
            </div>
            <h1
              className={`text-white text-lg text-xl text-center w-[6rem] font-bold`}
            >
              {getLocalisedText(LOCALISATION_ID_ENUMS.buttonHover, config)}
            </h1>
          </>
        )}
        {isHovering && (
          <img
            className={`w-[12rem] h-[12rem] rounded-lg ${getQRStyles()}`}
            src={isHovering ? QRsrc : '#'}
            alt=''
          />
        )}
      </div>
    </>
  )
}
