/*

  s e a r c h  -  p r e s e n t a t i o n  u i  -  p a g e  n a v i g a t i o n
  Search PresentationUI PageNavigation

  :description:
  This file contains a definition of a component that acts as the interface to change page in the application.

*/

//
//  :react & redux:
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :code:
import {
  selectSearchState,
  updateSearchCurrentPage,
  updateSearchProductsDisplay
} from '../../../../features/showcase/redux/showcaseSlice'
import { smoothScrollToSelector } from '../../../../features/showcase/redux/showcaseAPI'
import { LOCALISATION_ID_ENUMS } from '../../../../statics'
import { getLocalisedText } from '../../../../js/helpers'

export const PageNavigationUI = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config, products, page, displayMode, primaryBrandColour } = props

  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const dispatch = useDispatch()
  const SEARCH_STATE = useSelector(selectSearchState)

  useEffect(() => {
    try {
      const el = document.getElementById("change-my-text-showing")
      el.innerHTML = `${el.innerHTML.replace('Showing', getLocalisedText(LOCALISATION_ID_ENUMS.resultsShowing, config))}`
      el.innerHTML = `${el.innerHTML.replace('of', getLocalisedText(LOCALISATION_ID_ENUMS.resultsShowingOf, config))}`
    } catch (error) {
      console.error(error);
    }
  }, [SEARCH_STATE, config])

  //
  //  :rendering:
  //  Return our JSX for this component.
  const renderPageNavigationUI = () => {
    //
    //  :step 1:
    //  Define the maximum number of pages buttons visible at one time.
    const MAX_VISIBLE_PAGES = 6
    //
    //  :step 2:
    //  Gather all of the pages into page IDs so that we can clearly iterate through them.
    let pageIds = []
    for (let i = 0; i < SEARCH_STATE.pages.total; i++) {
      pageIds.push(i + 1)
    }
    //
    //  Enforce the limit of maximum visible pages.
    pageIds = pageIds.slice(
      Math.max(
        0,
        pageIds.indexOf(SEARCH_STATE.pages.current) -
          Math.ceil(MAX_VISIBLE_PAGES / 2)
      ),
      Math.max(
        MAX_VISIBLE_PAGES,
        pageIds.indexOf(SEARCH_STATE.pages.current) +
          Math.ceil(MAX_VISIBLE_PAGES / 2)
      )
    )

    //
    //  :step 3:
    //  Return the JSX for this component.
    return (
      <div className='w-full flex flex-col  justify-center items-center mb-8 mt-4'>
        <span id='change-my-text-showing' className='my-4 text-[#213038] text-center'>
          {SEARCH_STATE.text.showing}
        </span>
        <div className='flex w-full text-center gap-2 items-center justify-center'>
          {pageIds.map(pageId => {
            return (
              <div
                data-id={pageId}
                key={`page-navigation-${pageId}`}
                className={`w-[2rem] h-[2rem] cursor-pointer ${
                  SEARCH_STATE.pages.current === pageId
                    ? `bg-[${primaryBrandColour}]`
                    : 'bg-[#cccccc]'
                } rounded-full flex flex-row justify-center items-center text-center text-white`}
                onClick={event => {
                  //
                  //  :step 1:
                  //  Trigger our redux handler and navigate to the page clicked by the user.
                  dispatch(
                    updateSearchCurrentPage(parseInt(event.target.dataset.id))
                  )
                  //
                  //  :step 2:
                  //  Trigger an update for our search results.
                  dispatch(updateSearchProductsDisplay())
                  //
                  //  :step 3:
                  //  Smooth scroll back to the top of the document.
                  smoothScrollToSelector("#search-results")
                }}
              >
                <p data-id={pageId}>{pageId}</p>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return <div className='flex-1'>{renderPageNavigationUI()}</div>
}
