/*

  a r  s h o w c a s e  e v e n t  l o g g i n g
  AR Showcase Logging
  
  :description:
  Our logging interface for the app, can be used with app core.

  :init:
  APP_CORE.initialiseLoggingLibraryRuntime("custom", {
      distributor: distributor,
      language: language,
      environment: environment,
      location: page,
      session: session,
      user: user,
  }, ARShowcaseEventLogging);

  :use:
  APP_CORE.log.send({}, "functionName");

*/

//
//  :packages:
import { ESL } from '@eyekandy/app-core'

export class ARShowcaseEventLogging extends ESL {
  constructor (options) {
    //
    //  Construct from our superclass.
    super(options)

    //
    //  :custom:
    //  Define some custom attributes.
    this.serviceName = 'ar-showcase'
  }

  //
  //  :core:
  //  Basic events related to actions involving loading.

  logAppStart () {
    this.log('app-start', null, this.serviceName, null)
  }

  log404 () {
    this.log(`not-found-404`, null, this.serviceName, null)
  }

  logClickBack () {
    this.log('click-back', null, this.serviceName, null)
  }

  //
  //  :banners:
  //  Events related to banners.

  logClickSmartBanner (group) {
    this.log(`click-smart-banner`, group, this.serviceName, null)
  }

  logClickSponsoredBanner (name) {
    this.log(`click-sponsored-banner`, name, this.serviceName, null)
  }

  //
  //  :delivery:
  //  Delivery events.

  logDeliveryExpert () {
    this.log(`delivery-expert`, null, this.serviceName, null)
  }

  logDeliveryLanding () {
    this.log(`delivery-landing`, null, this.serviceName, null)
  }

  logDeliverySearch () {
    this.log(`delivery-search`, null, this.serviceName, null)
  }

  logDeliveryBrandPage (brand) {
    this.log(`delivery-brand`, brand, this.serviceName, null)
  }

  //
  //  :search:
  //  Events related to searching.

  logSearch (text) {
    this.log(`search-text`, text, this.serviceName, null)
  }

  logSearchByCategory (category) {
    this.log(`search-by-category`, category, this.serviceName, null)
  }

  logSearchByBrand (brand) {
    this.log(`search-by-brand`, brand, this.serviceName, null)
  }

  logSearchByBrandCategeory (brand, category) {
    this.log(
      `search-by-brand-category`,
      `${brand}-${category}`,
      this.serviceName,
      null
    )
  }

  logEnableBrandFilter (name) {
    this.log(`enable-filter-brand`, name, this.serviceName, null)
  }

  logDisableBrandFilter (name) {
    this.log(`disable-filter-brand`, name, this.serviceName, null)
  }

  logEnableCategoryFilter (name) {
    this.log(`enable-filter-category`, name, this.serviceName, null)
  }

  logDisableCategoryFilter (name) {
    this.log(`disable-filter-category`, name, this.serviceName, null)
  }

  logEnablePriceFilter (name) {
    this.log(`enable-filter-price`, name, this.serviceName, null)
  }

  logDisablePriceFilter (name) {
    this.log(`disable-filter-price`, name, this.serviceName, null)
  }

  logUpdateResultsSorting(value){
    this.log(`update-sort-results`, value, this.serviceName, null)
  }

  logUpdatePageSize(value){
    this.log(`update-page-size`, value, this.serviceName, null)
  }

  //
  //  :products:
  //  Interactions with products.

  logClickProductPage (arid) {
    this.log('click-product-page', null, this.serviceName, arid)
  }

  logClickAddToCart (arid) {
    this.log('add-to-cart', null, this.serviceName, arid)
  }

  //
  //  :engagement:
  //  User engagement with eyekandy services

  logClickARModelButton (arid) {
    this.log('click-ar-model', null, this.serviceName, arid)
  }

  logClickARAssistantButton (arid) {
    this.log('click-ar-assistant', null, this.serviceName, arid)
  }

  logARModelStart (arid) {
    this.log('start-ar-model', null, this.serviceName, arid)
  }

  logARAssistantStart (arid) {
    this.log('start-ar-assistant', null, this.serviceName, arid)
  }
}
