//
//  :react & redux:
import React from 'react'
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'

//
//  :code:
import { EkyPrice } from '../eky-price/EkyPrice'

export const SponsoredBanner = ({ currentPage, src, href, styles, displayMode, config, retailer, showPrices, showLabels }) => {
  if (!displayMode) { return }
  if (!config) { return }
  if (!retailer) { return }
  if (!currentPage) { return; }

  const sponsoredBannerBlock = config.pages[currentPage]["sponsored-banner"]

  const DEFAULTS = {
    styles: 'w-full mt-[2rem] cursor-pointer flex justify-center'
  }

  const onClickHandler = event => {
    //
    //  +:logging:
    APP_CORE.log.send({ name: 'default' }, 'logClickSponsoredBanner')
  }

  return (
    <>
      <div className='flex flex-col w-[90%] lg:w-[80%] 2xl:max-w-[1700px] items-center relative'>
        <div
          onClick={e => {
            onClickHandler(e)
          }}
          className={styles ? styles : DEFAULTS.styles}
        >
          <a
            href={href ? href : sponsoredBannerBlock.link}
            target='_blank'
            rel='noreferrer'
          >
            <img src={src ? src : sponsoredBannerBlock[displayMode]} alt='' />
          </a>
        </div>
        {showPrices && <EkyPrice config={config} section={"sponsored"}></EkyPrice>}
        {showLabels && <EkyPrice config={config} section={"sponsored-without-price"}></EkyPrice>}
      </div>

    </>
  )
}
