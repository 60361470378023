/*

  E K Y  P R I C E
  eky price

  :description:
  A component to handle displaying the price of each section across the pages

*/

//
//  :react:
import React from 'react';
import { getLocalisedString } from '../../../js/utils';
import { FilledDownIcon } from '../svgs/FilledDownIcon';

//
//  :svg:
import { FilledLeftIcon } from '../svgs/FilledLeftIcon';
import { FilledRightIcon } from '../svgs/FilledRightIcon';
import { FilledUpArrow } from '../svgs/FilledUpArrow';

//
// :statics:
const POSITION_STYLE_MAPPING = {
  "W": "left-[-11%] top-[1%]",
  "E": "right-[3%] top-[1%]",
  "N": "bottom-[8rem]",
  "S": "top-[100%] left-[1%]"
}

//
//  :component
export const EkyPrice = ({ page, section, config, customPosition }) => {
  if (!config) return;
  if (!section) return;

  //
  //  Get our values from config
  const getPositionStyle = () => {
    if (customPosition) { return customPosition; }
    return POSITION_STYLE_MAPPING[getPosition()];
  }

  const getTextColourStyle = () => {
    return `text-white`;
  }

  const getPosition = () => {
    //
    // Get the "mapping" block from config
    const { mapping } = config || {};
  
    //
    // Find our arrow position if available
    const arrowPosition = mapping?.["pricing-labels"]?.[section]?.["arrow-position"];
  
    //
    // Return the arrow position if it exists, otherwise return "W"
    return arrowPosition || "W";
  };

  const arrowIcon = () => {
    const position = getPosition();

    if (position === "E") {
      return <>
        <div className='absolute right-[100%] top-[16%]'>
          <FilledLeftIcon fill={"#0CB7EB"}></FilledLeftIcon>
        </div>
      </>
    }

    if (position === "W") {
      return <>
        <div className='absolute right-[-6.2rem] top-[1.5rem]'>
          <FilledRightIcon fill={"#0CB7EB"}></FilledRightIcon>
        </div>
      </>
    }

    if (position === "N") {
      return <>
        <div className='absolute left-[30%] top-[100%]'>
          <FilledDownIcon fill={"#0CB7EB"}></FilledDownIcon>
        </div>
      </>
    }

    if (position === "S") {
      return <>
        <div className='absolute left-[28%] bottom-[51%]'>
          <FilledUpArrow fill={"#0CB7EB"}></FilledUpArrow>
        </div>
      </>
    }
  }

  const getLabelContent = () => {
    let text = []
    if (config.mapping) {
      if (config.mapping["pricing-labels"]) {
        if (config.mapping["pricing-labels"][section]){
          text = config.mapping["pricing-labels"][section]["localisation-ids"]
        }
      }
    }

    if (text.length === 0) { return; }

    const content = text.map((section, index) => {
      return <>
        <div key={index} className="my-2">
          <p>{getLocalisedString(section)}</p>
        </div>
      </>
    })

    return content;
  }

  return <>
    <div className={`absolute ${getPositionStyle()} z-[9]`}>
      <div key={`${page}-${section}`} className={`eyekandy-body relative p-3 flex flex-col justify-center border-2 rounded-lg max-w-[26rem] h-fit drop-shadow-lg shadow-md text-lg bg-[#0CB7EB] ${getTextColourStyle()}`}>
        <div className='border p-1 rounded-lg'>
          {getLabelContent()}
        </div>
      </div>
        {arrowIcon()}
    </div>
  </>
}