/*

  l a n d i n g
  Landing

  :description:

  
*/

//
//  :react & redux:
import React, { useEffect, useState } from 'react'

//
//  :code:
import { LandingUIv2 } from './LandingUIv2'
import { APP_CORE } from '../../features/showcase/app-core/redux/memory'
import { deriveCategoriesForDisplay } from '../../js/helpers'

//
//  :component:
const Landing = props => {
  //
  //  :config:
  //  Add our top level config here.
  const { distributor, language, config, products, page, showPrices, styling, showLabels } = props

  const [categoriesTodisplay, setCategoriesTodisplay] = useState()

  useEffect(() => {
    //
    //  +:logging:
    APP_CORE.log.libs.custom.location = 'landing'
    if (config.core) {
      if (config.core['live-url']) {
        if (config.core['live-url'].includes(document.referrer) && document.referrer !== '') {
          APP_CORE.log.libs.custom.location = `${config.core['live-url']}#${'landing'}`
        }
      }
    }
    APP_CORE.log.send({}, 'logDeliveryLanding')
  }, [config])

  //
  //  :hide live expert:
  useEffect(() => {
    if (document.querySelector('#vz-e-parent-dv')) {
      if (!document.querySelector('#vz-e-parent-dv').classList.contains('hidden')) {
        document.querySelector('#vz-e-parent-dv').classList.add('hidden')
      }
    }
  }, [])

  //
  //  UseEffect for updating the container height of our parent container
  useEffect(() => {
    if (window.parent) {
      if (config.core.resizeContainer) {
        window.parent.postMessage({ scrollHeight: 1000, action: 'update-showcase-embed-height' }, '*')
      }
    }
  }, [config])

  //
  //  Derive categories to display
  useEffect(() => {
    setCategoriesTodisplay(deriveCategoriesForDisplay(products))
  }, [products])

  //
  //  :render:
  //  Render out our UI component.
  return (
    <LandingUIv2
      products={products}
      distributor={distributor}
      language={language}
      config={config}
      page={page}
      showPrices={showPrices}
      showLabels={showLabels}
      styling={styling}
      categoriesTodisplay={categoriesTodisplay}
    ></LandingUIv2>
  )
}

export default Landing
