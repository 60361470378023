import { configureStore } from '@reduxjs/toolkit';
import showcaseSlice from '../features/showcase/redux/showcaseSlice';
import appCoreReducer from '../features/showcase/app-core/redux/appCoreSlice'

export const store = configureStore({
  reducer: {
    showcase: showcaseSlice,
    appCore: appCoreReducer
  },
});
