/*

  s e a r c h  -  p r e s e n t a t i o n  u i  -  p a g e  s i z e
  Search PresentationUI Page Size

  :description:

*/

//
//  :react & redux:
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :code:
import {
  selectSearchState,
  updateSearchProductsDisplay,
  updateSearchProductsPerPage
} from '../../../../features/showcase/redux/showcaseSlice'
import { APP_CORE } from '../../../../features/showcase/app-core/redux/memory'
import { getLocalisedText } from '../../../../js/helpers'

export const PageSizeUI = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config, products, page, displayMode } = props

  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const dispatch = useDispatch()
  const SEARCH_STATE = useSelector(selectSearchState)

  const getLocalisedFilteringText = (value) => {
    const MAPPING = {
      "Show: 20 products": "results-show-20-products",
      "Show: 40 products": "results-show-40-products",
      "Show: All products": "results-show-all-products",
    }
    return getLocalisedText(MAPPING[value], config);
  }

  //
  //  :rendering:
  //  Return our JSX for this component.
  const renderPageSizeUI = () => {
    return (
      <select
        onChange={e => {
          //
          //  :step 1:
          //  Trigger our redux handler and pass it the new page limit.
          dispatch(updateSearchProductsPerPage(Number(e.target.value)))
          //
          //  :step 2:
          //  Trigger an update for our search results.
          dispatch(updateSearchProductsDisplay())

          //
          //  +:logging:
          APP_CORE.log.send(
            { value: e.target.value },
            'logUpdateResultsSorting'
          )
        }}
        className='p-1 w-[12rem] h-[2.3rem] bg-white border border-2 rounded-lg'
        name='sort-by'
        id=''
      >
        {SEARCH_STATE.pages.sizes.map(config => {
          return (
            <option key={config.size} value={config.size}>
              {getLocalisedFilteringText(`Show: ${config.name} products`)}
              {/* Show: {config.name} products */}
            </option>
          )
        })}
      </select>
    )
  }

  return <div className='mr-2'>{renderPageSizeUI()}</div>
}
