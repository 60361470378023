/*

  s e a r c h  -  p r e s e n t a t i o n  u i  -  s e a r c h b o x
  Search PresentationUI PageNavigation

  :description:
  This file contains a definition of a component that handles user input for searching.

*/

//
//  :react & redux:
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :code:
import {
  selectSearchState,
  updateSearchFilteringText,
  updateSearchProductsDisplay
} from '../../../../features/showcase/redux/showcaseSlice'
import { SearchIcon } from '../../../../components/ui/svgs/SearchIcon'
import { APP_CORE } from '../../../../features/showcase/app-core/redux/memory'
import { LOCALISATION_ID_ENUMS } from '../../../../statics'
import { getLocalisedText } from '../../../../js/helpers'

export const SearchboxUI = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const {
    // eslint-disable-next-line
    distributor,
    // eslint-disable-next-line
    language,
    // eslint-disable-next-line
    config,
    // eslint-disable-next-line
    products,
    // eslint-disable-next-line
    page,
    // eslint-disable-next-line
    displayMode,
    primaryBrandColour
  } = props
  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const dispatch = useDispatch()
  const SEARCH_STATE = useSelector(selectSearchState)

  //
  //  :react:
  //  We'll need some react state too to handle inputs.
  const [searchText, setSearchText] = useState(
    new URLSearchParams(window.location.search).get('q') ||
      '' ||
      SEARCH_STATE.filters.text
  )

  const onChangeSearchbox = async event => {
    //
    //  :step 1:
    //  Read the value of the text the user entered into React state.
    setSearchText(event.target.value)
    //
    //  :step 2:
    //  Read the value the user entered into Redux state.
    dispatch(updateSearchFilteringText(event.target.value))
    //
    //  :step 3:
    //  Trigger redux to update the search results.
    dispatch(updateSearchProductsDisplay())
    //
    //  +:logging:
    APP_CORE.log.send({ text: event.target.value }, 'logSearch')
  }

  //
  //  :rendering:
  //  Return our JSX for this component.
  const renderSearchboxUI = () => {
    return (
      <div className='w-full flex mb-4 mt-2'>
        <div className='flex-1 py-1 border-2 border-slate-500 pr-1 rounded-full bg-white flex justify-between transition-all hover:shadow-md'>
          <input
            type='text'
            value={searchText}
            onChange={e => onChangeSearchbox(e)}
            className='outline-none bg-transparent px-4 sm:w-80 md:w-96'
            name='search-input'
            id='search-for-products'
            placeholder={getLocalisedText(
              LOCALISATION_ID_ENUMS.resultsInputPlaceholder, config
            )}
          />
          <div
            className='h-8 w-8 rounded-full cursor-pointer'
            style={{ backgroundColor: primaryBrandColour }}
            onClick={e => {
              //
              //  +:logging:
              dispatch(updateSearchProductsDisplay())
              APP_CORE.log.send({ text: searchText }, 'logSearch')
            }}
          >
            <SearchIcon styles='mt-[0.3rem] ml-[0.2rem]'></SearchIcon>
          </div>
        </div>
      </div>
    )
  }

  return <div className='flex-1'>{renderSearchboxUI()}</div>
}
