/*

  s h o w c a s e  a p i
  Showcase API

  :description:
  Our slice for ShowCase is quite long, it's clearer to write it as functions that return the 
  value we want, based on the state they are given.

  Instead of working out the logic and then writing to state from inside the reducer, we'll abstract the
  logic here and only write from inside the reducer.

*/

//
//  :packages:
import md5 from 'md5'

export const smoothScrollToTopOfApp = () => {
  document.querySelector('#root').scrollIntoView({
    behavior: 'smooth'
  })
}

export const smoothScrollToSelector = selector => {
  const node = document.querySelector(selector)
  if (!selector) {
    smoothScrollToTopOfApp()
    return
  }
  node.scrollIntoView({
    behavior: 'smooth'
  })
}

export const renderHashForSearchState = searchState => {
  const products =
    searchState.products.all.length + searchState.products.display.length
  const filtering = JSON.stringify(searchState.filters)
  const pages = JSON.stringify(searchState.pages)
  return md5(`${products} ${filtering} ${pages}`)
}

export const readBrandFilterOptionsFromProducts = (products, existing) => {
  const brands_ = []
  products.map(product => {
    brands_.push(product.brand)
    return true
  })
  brands_.sort()
  const brands = {}
  for (let i = 0; i < brands_.length; i++) {
    if (existing) {
      if (existing[brands_[i]]) {
        brands[brands_[i]] = true
      }
    } else {
      brands[brands_[i]] = false
    }
  }
  return brands
}

export const readCategoryFilterOptionsFromProducts = (products, existing) => {
  const categories_ = []
  products.map(product => {
    categories_.push(product.category)
    return true
  })
  categories_.sort()
  const categories = {}
  for (let i = 0; i < categories_.length; i++) {
    if (existing) {
      if (existing[categories_[i]]) {
        categories[categories_[i]] = true
      }
    } else {
      categories[categories_[i]] = false
    }
  }
  return categories
}

export const readPriceWindowFilterOptionsFromProducts = (
  products,
  existing,
  allPriceWindows
) => {
  //
  //  There are two paths here, either we have existing filters or not.
  const priceWindows = {}
  //
  //  For all the products.
  products.map(product => {
    for (const [priceWindow, priceWindowConfig] of Object.entries(
      existing || allPriceWindows
    )) {
      if (
        product.price >= priceWindowConfig.from &&
        product.price <= priceWindowConfig.to
      ) {
        priceWindows[priceWindow] = priceWindowConfig
      }
    }
    return true
  })

  //
  //  We need to sort our object.
  //  TODO: Tidy this, being keys is a pain here as we need to sort the object.
  const ORDER = [
    '€0 to €39.99', '€40 to €49.99', '€50 to €59.99', '€60 to €69.99', '€70 to €79.99', '€80 to €89.99',
    '€90 to €99.99', '€100 to €499.99', '€500 to €999.99', '€1000+'
  ]
  const sortedPriceWindows = {}
  for(let i = 0; i < ORDER.length; i++){
    if(priceWindows[ORDER[i]]){
      sortedPriceWindows[ORDER[i]] = priceWindows[ORDER[i]]
    }
  }
  return sortedPriceWindows

  /*
  if (!existing) {
    existing = {}
  }
  const priceWindows = {}
  products.map(product => {
    let inActivePriceWindow = false
    //
    //  Iterate through all known price windows and evaluate whether it's a match or not.
    for (const [priceWindow, priceWindowConfig] of Object.entries(existing)) {
      if (
        product.price >= priceWindowConfig.from &&
        product.price <= priceWindowConfig.to
      ) {
        if (Object.keys(existing).length > 0) {
          inActivePriceWindow = true
        }
      }
    }
    //
    //  If this price window is a match.
    if (inActivePriceWindow) {
    }

    return true
  })
  return priceWindows
  */
}

export const filterIsActive = (filterConfig, key) => {
  let actives = 0
  // eslint-disable-next-line
  for (const [k, v] of Object.entries(filterConfig)) {
    if (key) {
      if (v) {
        if (v[key]) {
          actives += 1
        }
      }
    } else {
      if (v) {
        actives += 1
      }
    }
  }
  return actives !== 0
}

export const stringIncludes = (string_, value) => {
  if (string_) {
    if (string_.length) {
      if (string_.toLowerCase().includes(value.toLowerCase())) {
        return true
      }
    }
  }
  return false
}

export const shuffleArray = arrayToShuffle => {
  let currentIndex = arrayToShuffle.length,
    randomIndex

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    ;[arrayToShuffle[currentIndex], arrayToShuffle[randomIndex]] = [
      arrayToShuffle[randomIndex],
      arrayToShuffle[currentIndex]
    ]
  }
  return arrayToShuffle
}

export const upsertGetArgumentValuesToState = state => {
  //
  //  :step 2:
  //  In this early version we'll attempt to simply read from the URL when initialising.
  //  Will work so long as the /search route isn't embedded within another page.
  const searchParams = new URLSearchParams(window.location.search)
  const GET_PARAMS_MAP = {
    text: 'q',
    brands: 'b',
    categories: 'c'
  }
  for (const [ourAttributeName, getAttributeName] of Object.entries(
    GET_PARAMS_MAP
  )) {
    if (searchParams.get(getAttributeName)) {
      if (ourAttributeName === 'text') {
        state.search.filters.text = searchParams.get(getAttributeName)
      } else {
        //
        //  Only set the given filter.
        state.search.filters[ourAttributeName][
          searchParams.get(getAttributeName)
        ] = true
      }
    }
  }
}
