//
//  :react && redux:
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'
import { getLocalisedText } from '../../../js/helpers'

//
//  :code:
import { getPath } from '../../../js/utils'
import { PAGES_TO_NAMES } from '../../../routes/routing'

//
//  :statics
import { LOCALISATION_ID_ENUMS } from '../../../statics'

//
//  :render:
export const SmartBanner = props => {
  //
  //  Read all of our inputs.
  let { group, distributor, language, shouldBeLink, config } = props

  const navigate = useNavigate()
  shouldBeLink = shouldBeLink === undefined ? true : false

  //
  //  :event handlers:
  const onClickHandler = () => {
    if (shouldBeLink) {
      window.scrollTo(0, 0)
      document.querySelector('#root').classList.add('opacity-0')
      //
      //  +:logging:
      APP_CORE.log.send({ group: group }, 'logClickSmartBanner')
      setTimeout(() => {
        navigate(getPath(distributor, language, PAGES_TO_NAMES[group]))
        setTimeout(() => {
          document.querySelector('#root').classList.remove('opacity-0')
        }, 180)
      }, 250)
    }
  }

  if (group === 'expert') {
    return (
      <>
        <div className="w-[90%] lg:w-[80%] h-fit lg:h-[280px] xl:max-w-[1500px] lg:mb-[5rem] relative z-[2] rounded-lg">
          <div className="w-full h-fit lg:h-full mt-[2rem] mb-[1rem] lg:mb-[0rem] lg:mt-[4rem] bg-[#fdecbd] flex flex-col lg:flex-row rounded-lg">
            <div className="flex flex-col text-center max-w-full lg:max-w-[55%] p-[1.5rem] justify-center">
              <h1 className="text-[#4c12a1] text-[3rem] xl:text-[4rem]">
                {getLocalisedText(LOCALISATION_ID_ENUMS.expertBannerHelpMeChoose, config)}
              </h1>
              <h1 className="text-[#213038] text-[1.5rem]">
                {getLocalisedText(LOCALISATION_ID_ENUMS.expertBannerHelpMeChooseBody, config)}
              </h1>
            </div>
            <div className="lg:absolute left-[24%] top-[154%] lg:top-[-11%] lg:left-[63%] flex justify-center">
              <img
                src="https://cdn.pointandplace.com/services/ar-showcase/assets/images/banners/expert/individual-assets/expert-in-phone-001.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  if (group === 'samsung') {
    return (
      <>
        <div
          className="w-[90%] lg:w-[80%] h-fit lg:h-[280px] 2xl:max-w-[1700px] lg:mb-[5rem] relative z-[2] rounded-lg cursor-pointer"
          onClick={() => {
            onClickHandler()
          }}
        >
          <div className="w-full h-fit lg:h-full mt-[1rem] mb-[1rem] lg:mb-[0rem] lg:mt-[4rem] bg-[#cfe6e5] flex flex-col lg:flex-row rounded-lg px-4 pt-4">
            <div className="flex flex-col text-center max-w-full lg:max-w-[50%] p-2 justify-evenly items-center lg:ml-[8%]">
              <img
                className="w-[19rem] h-[3rem]"
                src="https://cdn.pointandplace.com/services/ar-showcase/assets/images/banners/samsung/header/individual-assets/samsung-title-001.png"
                alt=""
              />
              <h1 className="text-[#4c12a1] text-[2rem] max-w-[23rem] font-bold">{getLocalisedText('branded-samsung-banner-text', config)}</h1>
            </div>
            <div className="lg:absolute lg:top-[9.5%] lg:left-[50%] flex justify-center lg:w-[31rem] lg:h-[19.8rem]">
              <img
                src="https://cdn.pointandplace.com/services/ar-showcase/assets/images/banners/samsung/header/individual-assets/expert-in-phone-002.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  if (group === 'how-to') {
    return (
      <>
        <div className="w-full h-fit lg:h-[280px] lg:mb-[5rem] relative z-[2] rounded-lg cursor-pointer">
          <div className="w-full h-fit lg:h-full mt-[1rem] mb-[1rem] lg:mb-[0rem] lg:mt-[4rem] bg-[#f5d3e5] flex flex-col items-center lg:flex-row rounded-lg px-4 pt-4">
            <h1 className="text-[#4c12a1] text-[3rem] xl:text-[4rem]">See it in your space</h1>
          </div>
        </div>
      </>
    )
  }

  return <>OK</>

  //
  //  :rendering:
  //
}
