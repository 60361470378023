//
//  :react & redux:
import React, { useEffect, useState } from 'react'

//
//  :code:
import { AddToCart } from '../../components/ui/buttons/AddToCart'
import { SponsoredBanner } from '../../components/ui/sponsored-banner/SponsoredBanner'
import { useNavigate } from 'react-router-dom'
import { AllInOneSlimV2 } from '../../components/ui/buttons/All-in-one-slim-v2'
import { AugmentedRealityIcon } from '../../components/ui/svgs/AugmentedRealityIcon'
import { PAGES_TO_NAMES } from '../routing'
import { getPath } from '../../js/utils'
import { SmartBanner } from '../../components/ui/smart-banner/SmartBanner'
import { HelpMeChooseCards } from '../../components/ui/help-me-choose-cards/HelpMeChooseCards'
import { Back } from '../../components/ui/buttons/Back'
import { APP_CORE } from '../../features/showcase/app-core/redux/memory'
import { getLocalisedText, getPriceText } from '../../js/helpers'
import { CarouselV2 } from '../../components/layout/carousel/CarouselV2'

//
//  :statics:
// const discoverSamsungObject = {
//   TVs: 'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/television-001.png',
//   Cooking:
//     'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/cooking-001.jpeg',
//   Laundry:
//     'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/laundry-001.jpeg',
//   Tablets:
//     'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/tablets-001.jpeg',
//   Wearables:
//     'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/wearables-001.jpeg',
//   Audio: 'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/audio-001.jpeg',
//   'Mobile Phones':
//     'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/mobile-phones-001.jpeg',
// }

const discoverSamsungObject = {
  en: {
    TVs: 'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/television-001.png',
    Cooking:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/cooking-001.jpeg',
    Laundry:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/laundry-001.jpeg',
    Tablets:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/tablets-001.jpeg',
    Wearables:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/wearables-001.jpeg',
    Audio:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/audio-001.jpeg',
    'Mobile Phones':
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/mobile-phones-001.jpeg',
  },
  ie: {
    TVs: 'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/television-001.png',
    Cooking:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/cooking-001.jpeg',
    Laundry:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/laundry-001.jpeg',
    Tablets:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/tablets-001.jpeg',
    Wearables:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/wearables-001.jpeg',
    Audio:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/audio-001.jpeg',
    'Mobile Phones':
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/mobile-phones-001.jpeg',
  },
  fr: {
    Téléviseurs:
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/television-001.png',
    "Montres connectées":
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/wearables-001.jpeg',
    "Ordinateurs portables":
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/laptops-001.png',
    'Smartphones':
      'https://cdn.pointandplace.com/services/ar-showcase/assets/images/samsung/discover-categories/mobile-phones-001.jpeg',
  },
}
export const SamsungUI = props => {
  const {
    // eslint-disable-next-line
    brand,
    distributor,
    language,
    config,
    // eslint-disable-next-line
    products,
    // eslint-disable-next-line
    page,
    popularProducts,
    styling,
  } = props

  const primaryBrandColour = styling['primary-brand-colour']
  const titleTextColour = styling['title-text-colour']

  const group = 'Samsung'

  const getScreenSize = () => {
    return window.innerWidth >= 1024 ? 'desktop' : 'mobile'
  }

  const [displayMode, setDisplayMode] = useState(getScreenSize())
  const navigate = useNavigate()

  const onResizePageHandler = event => {
    if (getScreenSize() !== displayMode) {
      setDisplayMode(getScreenSize())
    }
  }

  window.addEventListener('resize', onResizePageHandler)

  const getBodyTextStyle = () => {
    return styling['body-font']
  }

  const getTitleStyle = () => {
    return `text-2xl ${styling['title-font']}`
  }

  useEffect(() => {
    if (document.querySelector('#vz-e-parent-dv')) {
      if (document.querySelector('#vz-e-parent-dv').classList.contains('hidden')) {
        document.querySelector('#vz-e-parent-dv').classList.remove('hidden')
      }
    }
  }, [])

  if (!config) { return }

  //
  //  When the user clicks on the product image or title, a new tab should open with the product page
  const openProductPage = (uri, arid) => {
    const anchor = document.createElement('a')
    anchor.appendChild(document.createElement('img'))
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('rel', 'noreferrer')
    anchor.setAttribute('href', uri)
    anchor.click()

    //
    //  +:logging:
    APP_CORE.log.send({ arid: arid }, 'logClickProductPage')
  }

  //
  //  Quick fix
  //  :TODO: update localisation
  const buttonText = () =>{
    if (config){
      if (config.core){
        return config.core.language === "fr" ? "Découvrez en RA" : "Discover in AR"
      }
    }
  }

  const popularSamsungProductsSection = () => {
    const products = popularProducts.map(product => (
      <div
        key={product.sku}
        className="relative h-[23rem] w-[15rem] xl:w-[17rem] xl:h-[25rem] flex flex-col justify-center items-center bg-white border-2 border-slate-300 rounded-lg p-6"
      >
        <div
          className="w-auto h-auto bg-white mt-1 rounded cursor-pointer"
          onClick={() => {
            openProductPage(product['add-to-cart-url'], product.arid)
          }}
        >
          <img className="max-h-[11rem]" title={product.name} src={product.image} alt="" />
        </div>
        <div
          className={`w-[12rem] max-h-[5rem] min-h-[5rem] bg-white m-1 pt-3 flex flex-col text-center justify-evenly ${getBodyTextStyle()}`}
        >
          <p className="text-xs">{product.name}</p>
          <p className="text-sm mt-2">{getPriceText(product.price, config)}</p>
        </div>
        <div className="flex justify-center my-2">
          <AllInOneSlimV2
            config={config}
            primaryBrandColour={primaryBrandColour}
            product={product}
            arid={product.arid}
            buttonType={'hover'}
            displayMode={displayMode}
            uri={product['add-to-cart-url']}
          ></AllInOneSlimV2>
        </div>
        <div className="flex justify-center my-2">
          <AddToCart
            config={config}
            primaryBrandColour={primaryBrandColour}
            uri={product['add-to-cart-url']}
            arid={product.arid}
          ></AddToCart>
        </div>
      </div>
    ))

    return (
      <>
        <div className="w-full h-auto bg-[#f8f8f8]">
          <div className="flex justify-center text-center mb-[2.8rem] mt-[2rem] font-medium">
            <h2 className={getTitleStyle()} style={{ color: titleTextColour }}>
              {getLocalisedText('branded-title-popular-samsung-products', config)}
            </h2>
          </div>
          <div className="mx-2 flex justify-center">
            <div className="grid gap-x-6 gap-y-6 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-[4rem] md:mb-[4rem]">
              {products}
            </div>
          </div>
        </div>
      </>
    )
  }

  const discoverSamsungSection = () => {
    const onClickHandler = e => {
      const category = e.currentTarget.id

      window.scrollTo(0, 0)
      document.querySelector('#root').classList.add('opacity-0')
      //
      //  +:logging:
      APP_CORE.log.send({ brand: 'samsung', category: category }, 'logSearchByBrandCategory')
      setTimeout(() => {
        navigate(
          getPath(distributor, language, PAGES_TO_NAMES.search) + `?c=${category}&b=${group}`
        )
        setTimeout(() => {
          document.querySelector('#root').classList.remove('opacity-0')
        }, 180)
      }, 250)
    }

    let discoverProducts = []
    for (const [key, value] of Object.entries(discoverSamsungObject[config.core.language])) {
      discoverProducts.push(
        <div
          key={key}
          className="flex flex-col items-center border-2 border-slate-300 rounded-lg w-[17rem] mr-[1.5rem]"
        >
          <div
            className="w-full h-[3rem] flex justify-center items-center text-center bg-[#e3daf1] rounded-t-lg"
            style={{ color: primaryBrandColour }}
          >
            <p>{`${group} ${key}`}</p>
          </div>
          <div className="p-2">
            <img draggable={false} className="w-[11rem] h-[10.8rem] p-2" src={value} alt="" />
          </div>
          <div className="my-5">
            <div
              style={{ backgroundColor: primaryBrandColour }}
              className={`rounded-full text-white font-medium w-[11rem] h-[2.3rem] flex justify-center items-center text-center relative hover:scale-110 transition-all duration-500`}
            >
              <button
                id={key}
                className="flex p-1"
                onClick={e => {
                  onClickHandler(e)
                }}
              >
                <span className={`text-white`}>{buttonText()}</span>
                <AugmentedRealityIcon styles="ml-[1rem]"></AugmentedRealityIcon>
              </button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="w-full mb-[1rem] lg:mb-[4rem]">
          <div className="flex justify-center text-center mb-[2.8rem] mt-[2rem] font-medium">
            <h2 className={getTitleStyle()} style={{ color: titleTextColour }}>
              {getLocalisedText('branded-title-discover-samsung', config)}
            </h2>
          </div>
          <div className="justify-center relative w-full items-center">
            <CarouselV2 setWidth={false} config={config} buttonsContainerStyles={{ bottom: '0.5rem' }}>
              {discoverProducts}
            </CarouselV2>
          </div>
        </div>
      </>
    )
  }

  const liveExpert = () => {
    //
    // Make sure we have a live expert script for this retailer
    if (!config) { return }
    if (!config.core) {return}
    if (!config.core['live-expert']) { return }
    if (!config.core['live-expert'].enabled){ return }

    //
    //  Get the live expert block
    const liveExpertBlock = config.core['live-expert']

    //
    // If the script does not already exist, create it
    if (!document.querySelector("[data-id='aae-script-id']") && !document.querySelector('#vz-e-parent-dv')) {
      var scriptObj = document.createElement('script')
      
      //
      //  Add the required attributes from this retailers config 
      for (const [key, value] of Object.entries(liveExpertBlock.attributes)){
        scriptObj.setAttribute(key, value)
      }

      //
      //  Add the src and other required script values 
      scriptObj.src = liveExpertBlock.src
      scriptObj.type = 'text/javascript'
      scriptObj.async = true
      
      //
      //  Append script to head
      document.head.appendChild(scriptObj)
    }
  }

  return (
    <>
      <div className={`flex flex-col w-full min-h-full items-center justify-center`}>
        <div className="flex flex-col w-full items-center">
          <SponsoredBanner
            retailer={'Samsung GB'}
            config={config}
            displayMode={displayMode}
            currentPage={'samsung'}
          ></SponsoredBanner>

          <SmartBanner group={'samsung'} distributor={distributor} language={language}></SmartBanner>

          <Back
            buttonText={'< Back'}
            classes={'w-[90%] lg:w-[80%] mt-2 2xl:max-w-[1700px] '}
            primaryBrandColour={primaryBrandColour}
            config={config}
          ></Back>
        </div>

        {/* popular samsung products  */}
        {popularSamsungProductsSection()}

        <div className="flex flex-col w-[90%] lg:w-[80%] items-center">
          {/* discover Samsung  */}
          {discoverSamsungSection()}
        </div>
        <SmartBanner group={'expert'} distributor={distributor} language={language}></SmartBanner>

        <HelpMeChooseCards
          primaryBrandColour={primaryBrandColour}
          displayMode={displayMode}
          config={config}
        ></HelpMeChooseCards>
      </div>
      {liveExpert()}
    </>
  )
}
