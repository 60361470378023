/*

  s e a r c h  -  p r e s e n t a t i o n  u i  -  p a g e  h e a d i n g
  Search PresentationUI PageHeading

  :description:
  This file contains a definition of a component that will display a heading at the top of our search
  route with information about how many products are visible etc.

*/

//
//  :react & redux:
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

//
//  :code:
import {
  selectSearchState,
} from '../../../../features/showcase/redux/showcaseSlice'
import { getLocalisedText } from '../../../../js/helpers'
import { LOCALISATION_ID_ENUMS } from '../../../../statics'

export const PageHeadingUI = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config, products, page, displayMode, primaryBrandColour } = props

  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const SEARCH_STATE = useSelector(selectSearchState)

  useEffect(() => {
    try {
      const el = document.getElementById("change-my-text-on-load")
      el.innerHTML = `${el.innerHTML.replace('results', getLocalisedText(LOCALISATION_ID_ENUMS.resultsTitleSubText, config))}`
    } catch (error) {
      console.error(error);
    }
  },[SEARCH_STATE, config])

  //
  //  :rendering:
  //  Return our JSX for this component.
  return (
    <div>
      <h1 className='text-center text-xl sm:text-2xl text-[#4c12a1] font-medium' style={{color: primaryBrandColour}}>
        {getLocalisedText(LOCALISATION_ID_ENUMS.resultsTitle, config)}
      </h1>
      <h1 id='change-my-text-on-load' className='text-center text-lg'>{SEARCH_STATE.text.heading}</h1>
    </div>
  )
}
