import axios from 'axios';

export const jsonGetRequestSafe = async (url) => {
  return new Promise(async (resolve) => {
    const headers = {
      "Content-Type": "application/json",
    }
    try {
      const response = await axios.get(`${url}`, { headers: headers })
      resolve({
        status: response.status,
        data: response.data
      })
    } catch (err) {
      //
      //  @Louis: Change back to 400 / data: {}
      resolve({ status: 400, data: err })
    }
  })
}
