/*
    a l l  i n  o n e  s l i m  v 2
    All-in-one-slim-v2

    :description:
    This component handles the "pointandplace" button as well as the popup

*/

//
//  :react:
import React from 'react'
import { useState } from 'react'
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'
import { getLocalisedText } from '../../../js/helpers'

//
//  :code:
import {
  getAvatarDeliveryURL,
  getModelDeliveryURL,
  isTruthy,
  launchIntoAR_
} from '../../../js/utils'

//
//  :statics:
import { LOCALISATION_ID_ENUMS } from '../../../statics'

//
//  :svgs:
import { AugmentedRealityIcon } from '../svgs/AugmentedRealityIcon'

//
//  :statics:
const TYPE_MAPPING = {
  model: getModelDeliveryURL,
  avatar: getAvatarDeliveryURL
}
const SERVICE_ENUMS = {
  model: 'ar-models',
  avatar: 'ar-assistant'
}
const DEFAULTS = {
  popupStyles:
    'absolute w-[15rem] h-[20rem] bg-[#4c13a2] flex flex-col justify-center items-center rounded-lg border p-4 z-10 top-[-21rem]',
  buttonStyles:
    'rounded-full text-white font-medium h-[2rem] w-[8rem] flex justify-center items-center text-center relative',
  env: 'dev',
  buttonType: 'click'
}

export const AllInOneSlimV2 = props => {
  const [isHovering, setIsHovering] = useState(false)

  let {
    buttonTextStyles,
    buttonType,
    QRType,
    arid,
    env,
    displayMode,
    uri,
    product,
    primaryBrandColour,
    config,
    type
  } = props

  if (!isTruthy(arid)) {
    return
  }
  if (!type) {
    type = 'ar-models'
  }

  //
  //  set our defaults
  QRType = QRType === 'avatar' ? 'avatar' : 'model'
  env = 'prod'
  buttonType = buttonType === 'hover' ? 'hover' : DEFAULTS.buttonType
  if (buttonType === 'hover' && displayMode === 'mobile') {
    buttonType = 'click'
  }

  //
  //  Get the delivery URL
  const QRsrc = TYPE_MAPPING[QRType](arid, env)
  //
  //  form the addToCartURL
  const addToCartURL = product
    ? `${product['add-to-cart-url']}?eky-a2c`
    : `${uri}?eky-a2c`

  const launchIntoAR = () => {
    launchIntoAR_(
      SERVICE_ENUMS[QRType],
      arid,
      env,
      addToCartURL,
      product,
      primaryBrandColour
    )
  }

  const handleButtonClick = () => {
    //
    //  +:logging:
    APP_CORE.log.send(
      { arid: arid },
      QRType === 'avatar' ? 'logARAssistantStart' : 'logARModelStart'
    )
    if (displayMode === 'mobile' || APP_CORE.env.canHandleAR) {
      launchIntoAR()
      return
    }
  }
  const handleButtonHoverStart = () => {
    //
    //  +:logging:
    APP_CORE.log.send(
      { arid: arid },
      QRType === 'avatar'
        ? 'logClickARAssistantButton'
        : 'logClickARModelButton'
    )
    if (APP_CORE.env.canHandleAR) {
      launchIntoAR()
    }

    setIsHovering(true)
  }
  const handleButtonHoverEnd = () => {
    setIsHovering(false)
  }
  const getPopupStyles = () => {
    if (!config) {
      return
    }
    if (isHovering && !APP_CORE.env.canHandleAR) {
      return `${config.core.language}-popup-visible`
    } else {
      return `${config.core.language}-popup-hidden`
    }
  }

  const getButtonStyles = () => {
    if (config) {
      if (config.core) {
        const LANGUAGE_STYLE_MAPING = {
          ie: 'rounded-full text-white font-medium h-[2rem] w-[8rem] flex justify-center items-center text-center relative',
          fr: 'rounded-full text-white font-medium h-[2rem] w-[9.5rem] flex justify-center items-center text-center relative',
          en: 'rounded-full text-white font-medium h-[2rem] w-[8rem] flex justify-center items-center text-center relative'
        }
        return LANGUAGE_STYLE_MAPING[config.core.language]
      }
    }
  }

  const allInOnePopup = () => {
    return (
      <>
        <div
          id={arid}
          className={getPopupStyles()}
          style={{ backgroundColor: primaryBrandColour }}
        >
          <div className='text-center text-white text-lg w-fit mb-2'>
            <h1 className='font-semibold'>
              {getLocalisedText(LOCALISATION_ID_ENUMS.buttonPopupTitle, config)}
            </h1>
          </div>
          <div className='mb-2'>
            <img
              className={`w-[13rem] h-[13rem] rounded-lg ${
                isHovering ? '' : 'opacity-0'
              }`}
              src={isHovering ? QRsrc : '#'}
              alt=''
            />
            <p className='removed'></p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {buttonType === 'click' ? (
        <div
          className={getButtonStyles()}
          style={{ backgroundColor: primaryBrandColour }}
        >
          <button
            style={{ backgroundColor: primaryBrandColour }}
            className={`${getButtonStyles()} hover:scale-110 transition-all duration-500`}
            onClick={() => {
              handleButtonClick(true)
            }}
          >
            <span className={buttonTextStyles}>
              {getLocalisedText(LOCALISATION_ID_ENUMS.buttonSeeInAr, config)}
            </span>
            <AugmentedRealityIcon styles='ml-[1rem]'></AugmentedRealityIcon>
          </button>
        </div>
      ) : (
        <div
          className={getButtonStyles()}
          style={{ backgroundColor: primaryBrandColour }}
        >
          <button
            className={`${getButtonStyles()} hover:scale-110 transition-all duration-500`}
            style={{ backgroundColor: primaryBrandColour }}
            onMouseLeave={() => {
              handleButtonHoverEnd()
            }}
            onMouseEnter={() => {
              handleButtonHoverStart(true)
            }}
          >
            <p className={buttonTextStyles}>
              {type === 'expert'
                ? getLocalisedText(LOCALISATION_ID_ENUMS.buttonExpert, config)
                : getLocalisedText(LOCALISATION_ID_ENUMS.buttonSeeInAr, config)}
            </p>
            <AugmentedRealityIcon styles='ml-[1rem]'></AugmentedRealityIcon>
          </button>
          {allInOnePopup()}
        </div>
      )}
    </>
  )
}
