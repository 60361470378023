/*
​
  n o t  f o u n d
  Not Found
​
  :description:
  Our main component to represent the 404 Not Found page.
  
*/

//
//  :react & redux:
import React from 'react'
import { useNavigate } from 'react-router-dom'

//
//  :code:
import { getPath } from '../../js/utils'
import { PAGES_TO_NAMES } from '../routing'

//
//  :component:
const NotFound = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config } = props
  const navigate = useNavigate()

  const onCickHandler = () => {
    //
    //  cheating here
    window.scrollTo(0, 0)
    navigate(
      getPath(
        '1e0c13f9-f51b-4990-a2c4-9f81cfc1a0ba',
        'ie',
        PAGES_TO_NAMES.landing
      )
    )
  }

  return (
    <div className='w-screen h-screen flex flex-1 bg-gray-700 text-white items-center justify-center'>
      <div className='w-96'>
        <h1 className='text-5xl text-center'>not found</h1>
        <p className='text-xl mb-2 text-center'>
          the requested resource does not exist at this time.
        </p>
        <div className='flex justify-center'>
          <code className='bg-gray-900 rounded-lg text-sm text-white p-2'>
            {window.location.pathname}
          </code>
        </div>
        <p
          className='text-xl mb-2 text-center underline cursor-pointer underline-offset-2'
          onClick={() => {
            onCickHandler()
          }}
        >
          Looking for Currys IE Showcase?
        </p>
      </div>
    </div>
  )
}

export default NotFound
