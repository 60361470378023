import React from 'react'

export const RightArrowIcon = props => {
  const fill = props.fill ? props.fill : 'white'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24">
      <path fill={fill} d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
    </svg>
  )
}
