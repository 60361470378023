/*

  s e a r c h  r e s u l t s
  Search results

  :description:
  
*/

//
//  :react & redux:
import React, { useEffect } from 'react'
import { useState } from 'react'

//
//  :code:
import { SearchResultsUIv3 } from './SearchResultsUIv3'
import { APP_CORE } from '../../features/showcase/app-core/redux/memory'

//
//  :component:
const Search = ({ distributor, language, config, products, page, styling }) => {
  //
  //  :config:
  //  Add our top level config here.
  const primaryBrandColour = styling['primary-brand-colour']

  //
  //  :redux:
  //  Set up functions/calls for redux.

  const getScreenSize = () => {
    return window.innerWidth >= 1024 ? 'desktop' : 'mobile'
  }

  const [displayMode, setDisplayMode] = useState(getScreenSize())

  //
  //  :init:
  //  On startup we want to load our products from here.
  useEffect(() => {
    //
    //  +:logging:
    APP_CORE.log.libs.custom.location = 'search'
    if (config.core) {
      if (config.core['live-url']) {
        if (config.core['live-url'].includes(document.referrer) && document.referrer !== '') {
          APP_CORE.log.libs.custom.location = `${config.core['live-url']}#${'search'}`
        }
      }
    }
    APP_CORE.log.send({}, 'logDeliverySearch')
  }, [config])

  useEffect(() => {
    if (document.querySelector('#vz-e-parent-dv')) {
      if (!document.querySelector('#vz-e-parent-dv').classList.contains('hidden')) {
        document.querySelector('#vz-e-parent-dv').classList.add('hidden')
      }
    }
  }, [])

  //
  //  UseEffect for updating the container height of our parent container
  useEffect(() => {
    if (window.parent) {
      if (config.core.resizeContainer) {
        window.parent.postMessage({ scrollHeight: 1000, action: 'update-showcase-embed-height' }, '*')
      }
    }
  }, [config])

  //
  //  :redux:
  const onResizePageHandler = event => {
    if (getScreenSize() !== displayMode) {
      setDisplayMode(getScreenSize())
    }
  }

  window.addEventListener('resize', onResizePageHandler)

  return (
    <SearchResultsUIv3
      products={products}
      distributor={distributor}
      language={language}
      config={config}
      page={page}
      displayMode={displayMode}
      primaryBrandColour={primaryBrandColour}
      styling={styling}
    ></SearchResultsUIv3>
  )
}

export default Search
