//
//  :APP_CORE:
import { APP_CORE } from '../../../features/showcase/app-core/redux/memory'

//
//  :react:
import { useNavigate } from 'react-router-dom'

//
//  :statics:
import { LOCALISATION_ID_ENUMS } from '../../../statics'
import { PAGES_TO_NAMES } from '../../routing'

//
//  :utils:
import { getPath } from '../../../js/utils'

//
//  :helpers:
import { convertRetailerNameToClass, getLocalisedText, isSectionEnabled } from '../../../js/helpers'

//
//  :components:
import { EkyPrice } from '../../../components/ui/eky-price/EkyPrice'
import { CarouselV2 } from '../../../components/layout/carousel/CarouselV2'

//
//  :render:
export const ShopByBrandSection = ({ config, styling, language, distributor, showPrices, page, showLabels }) => {
  const navigate = useNavigate()
  const knownBrands = config.core['enabled-brand-stores']

  //
  //  Get the button styles for the individuel buttons
  const borderColour = brand => {
    if (knownBrands.includes(brand.toLowerCase())) {
      //  If the brand page is enabled, the border should be geen and faded in
      return `${convertRetailerNameToClass(config)}-active-brand`
    } else {
      //  If the brand page is not enabled, the border should be red and faded out.
      return `${convertRetailerNameToClass(config)}-inactive-brand`
    }
  }

  //
  //  Get the title styles for this retailer
  const titleStyleClass = () => {
    if (styling) {
      if (styling['title-font']) {
        return `text-2xl ${styling['title-font']}`
      }
    }
  }

  //
  //  Direct the user to the brand store if the brand store is enabled in the retailers config
  const shopByBrandClickHandler = brand => {
    if (knownBrands.includes(brand.toLowerCase())) {
      window.scrollTo(0, 0)
      document.querySelector('#root').classList.add('opacity-0')
      //
      //  +:logging:
      APP_CORE.log.send({ brand: brand }, 'logSearchByBrand')

      setTimeout(() => {
        navigate(getPath(distributor, language, PAGES_TO_NAMES.samsung))
        setTimeout(() => {
          document.querySelector('#root').classList.remove('opacity-0')
        }, 180)
      }, 250)
    }
  }

  const shopBybrands = []
  for (const [key, value] of Object.entries(config.mapping.brands)) {
    shopBybrands.push(
      <div
        key={key}
        alt={key}
        title={key}
        onClick={() => {
          shopByBrandClickHandler(key)
        }}
        className={`${convertRetailerNameToClass(config)}-shop-by-card-container select-none`}
      >
        <div className={`${convertRetailerNameToClass(config)}-shop-by-brand-images-container ${borderColour(key)}`}>
          <img
            className={`${convertRetailerNameToClass(config)}-shop-by-images`}
            draggable="false"
            src={value}
            alt=""
          />
        </div>
        {isSectionEnabled('brand-text', 'landing', config) && (
          <div className="flex justify-center text-center w-[8rem] mt-3 category-text">
            <p className="text-[16px] font-normal select-none">{key}</p>
          </div>
        )}
      </div>
    )
  }

  return (
    <>
      <div className="flex justify-center text-center mt-[2rem] mb-[1rem] font-medium">
        <h2 className={titleStyleClass()} style={{ color: styling['title-text-colour'] }}>
          {getLocalisedText(LOCALISATION_ID_ENUMS.titleShopByBrand, config)}
        </h2>
      </div>
      <div className="w-full justify-center relative w-[90%] lg:w-[80%] items-center">
        {showPrices && (
          <EkyPrice
            customPosition={'left-[-4%] bottom-[114%]'}
            config={config}
            section={'brands'}
            page={page}
          ></EkyPrice>
        )}
        {showLabels && (
          <EkyPrice
            customPosition={'left-[-4%] bottom-[114%]'}
            config={config}
            section={'brands-without-price'}
            page={page}
          ></EkyPrice>
        )}
        <CarouselV2 setWidth={false} config={config}>
          {shopBybrands}
        </CarouselV2>
      </div>
    </>
  )
}
