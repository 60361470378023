/*

  s e a r c h  -  p r e s e n t a t i o n  u i  -  r e s u l t s  s o r t i n g
  Search PresentationUI Results Sorting

  :description:

*/

//
//  :react & redux:
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :code:
import {
  selectSearchState,
  updateSearchOrderingDirectionAscending,
  updateSearchOrderingDirectionDescending,
  updateSearchOrderingKey,
  updateSearchProductsDisplay,
} from '../../../../features/showcase/redux/showcaseSlice'
import { getLocalisedText } from '../../../../js/helpers'
import { APP_CORE } from '../../../../features/showcase/app-core/redux/memory'

export const ResultsSortingUI = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config, products, page, displayMode } = props

  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const dispatch = useDispatch()
  const SEARCH_STATE = useSelector(selectSearchState)

  const getLocalisedFilteringText = value => {
    const MAPPING = {
      'Brand - A to Z': 'results-sort-by-brand-a',
      'Brand - Z to A': 'results-sort-by-brand-z',
      'Price - low to high': 'results-sort-by-price-high',
      'Price - high to low': 'results-sort-by-price-low',
      'Sort By: Relevance': 'results-sort-by-relevance',
    }
    return getLocalisedText(MAPPING[value], config)
  }

  //
  //  :rendering:
  //  Return our JSX for this component.
  const renderResultsSortingUI = () => {
    return (
      <select
        onChange={e => {
          //
          //  :step 1:
          //  We need to derive which redux handler to call and call it.
          const key = e.target.value.split('|')[0]
          const val = Number(e.target.value.split('|')[1])
          const reduxDirectionHandler =
            val >= 0 ? updateSearchOrderingDirectionAscending : updateSearchOrderingDirectionDescending
          dispatch(reduxDirectionHandler())
          //
          //  :step 2:
          //  We need to update the key that we are ordering against in redux.
          dispatch(updateSearchOrderingKey(key))
          //
          //  :step 3:
          //  This action requires us to update the view of the search results.
          dispatch(updateSearchProductsDisplay())

          //
          //  +:logging:
          APP_CORE.log.send(
            {
              key: `${key || 'default'}-${val >= 0 ? 'desc' : 'asc'}`
            },
            'logUpdateResultsSorting'
          )
        }}
        className="p-1 w-fit h-[2.3rem] bg-white border border-2 rounded-lg"
        name="sort-by"
        id=""
      >
        <option key={null} value={`|`}>
          {getLocalisedFilteringText('Sort By: Relevance')}
        </option>
        {SEARCH_STATE.ordering.orders.map(config => {
          const k = `${config.key}|${config.direction}`
          return (
            <option key={config.name} value={k}>
              {getLocalisedFilteringText(config.name)}
            </option>
          )
        })}
      </select>
    )
  }

  return <div className="mr-2">{renderResultsSortingUI()}</div>
}
