//
//  :react:
import React, { useState, useRef, useEffect } from 'react'
import { convertRetailerNameToClass } from '../../../js/helpers'
import { LeftArrowIcon } from '../../ui/svgs/LeftArrowIcon'
import { RightArrowIcon } from '../../ui/svgs/RightArrowIcon'

//
//  :css:
import './carousel.css'

//
//  :render:
export const CarouselV2 = ({ children, setWidth, config, buttonsContainerStyles }) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [currentX, setCurrentX] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)
  const [numItems, setNumItems] = useState(0)
  const containerRef = useRef(null)
  const itemRef = useRef(null)

  const childWidth = setWidth ? 'calc(4.82581%)' : ''
  
  //
  //  update the client width and the amount of elements passed to this component when those elements change
  useEffect(() => {
    const item = containerRef.current
    if (item) {
      setItemWidth(item.clientWidth)
    }
    setNumItems(children.length)
  }, [children])

  if (!config) {
    return
  }

  //
  //  Let the state know we are currently dragging the items
  const handleMouseDown = e => {
    setIsDragging(true)
    setStartX(e.pageX)
  }

  //
  //  Handle the user drag event
  const handleMouseMove = e => {
    //
    // Check if the user is currently dragging the element
    if (isDragging) {
      //
      // Calculate the difference between the current horizontal position of the mouse and the starting position of the drag
      const diff = e.pageX - startX
      //
      // Define the minimum and maximum allowable translate values
      const minTranslate = 0
      const tempMaxTranslate = -(numItems - 1) * itemWidth * 0.1922581 // this value is adjustable
      //
      // Calculate the new translate value by adding the difference to the current horizontal position
      let newTranslate = currentX + diff
      //
      // Check if the new translate value is less than the maximum allowable value
      if (newTranslate < tempMaxTranslate) {
        //
        // If it is, set the translate value to the maximum allowable value
        newTranslate = tempMaxTranslate
        //
        // Check if the new translate value is greater than the minimum allowable value
      } else if (newTranslate > minTranslate) {
        //
        // If it is, set the translate value to the minimum allowable value
        newTranslate = minTranslate
      }
      setCurrentX(newTranslate)
      setStartX(e.pageX)
    }
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseLeave = () => {
    setIsDragging(false)
  }

  const onClickNext = e => {
    const tempMaxTranslate = -(numItems - 1) * itemWidth * 0.1822581 // this value is adjustable
    const newTranslate = currentX - itemRef.current.clientWidth
    if (newTranslate > tempMaxTranslate){
      setCurrentX(newTranslate)
    }
  }
  const onClickPrevious = e => {
    const newTranslate = currentX + itemRef.current.clientWidth
    if (newTranslate < 1){
      setCurrentX(newTranslate)
    }
  }

  return (
    <>
      <div className="w-full relative">
        <div className={`${convertRetailerNameToClass(config)}-carousel-buttons-container`} style={buttonsContainerStyles}>
          <button onClick={() => {onClickPrevious()}}>
            <div className="flex justify-center items-center">
              <div className={`${convertRetailerNameToClass(config)}-carousel-left-button-child mr-1`}>
                <LeftArrowIcon fill={config.styling['primary-brand-colour']}></LeftArrowIcon>
              </div>
            </div>
          </button>
          <button
            onClick={e => {
              onClickNext(e)
            }}
          >
            <div className="flex justify-center items-center">
              <div className={`${convertRetailerNameToClass(config)}-carousel-right-button-child`}>
                <RightArrowIcon fill={config.styling['primary-brand-colour']}></RightArrowIcon>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div
        ref={containerRef}
        className={`${convertRetailerNameToClass(config)}-carousel-container`}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        <div className="carousel-items flex" style={{ transform: `translate3d(${currentX}px, 0, 0)` }}>
          {React.Children.map(children, (child, index) => (
            <div ref={index === 0 ? itemRef : null} className="carousel-item select-none" style={{ width: childWidth }}>
              {child}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
