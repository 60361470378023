/*

  m e m o r y
  Memory

  :description:
  A JavaScript representation of something in RAM (singleton instances etc).

*/

//
//  :code:
import { EyekandyAppCore } from '@eyekandy/app-core';

//
//  :exports:
export const APP_CORE = new EyekandyAppCore()

window.TEMP_APP_CORE = APP_CORE
