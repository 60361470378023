/*

  s e a r c h  -  f i l t e r  u i  -  p r i c e  w i n d o w s
  Search FilterUI Price Windows

  :description:
  This file contains a definition of a filter component that is used to alter the price windows that
  show/hide products from search results.

*/

//
//  :react & redux:
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

//
//  :css:
import '../filterUIStyles.css'

//
//  :code:
import {
  selectSearchState,
  updateSearchFilteringDisablePriceWindow,
  updateSearchFilteringEnablePriceWindow,
  updateSearchProductsDisplay,
} from '../../../../features/showcase/redux/showcaseSlice'
import { smoothScrollToTopOfApp } from '../../../../features/showcase/redux/showcaseAPI'
import { APP_CORE } from '../../../../features/showcase/app-core/redux/memory'

export const PriceWindowsUI = props => {
  //
  //  :config:
  //  Add our top level config here.
  // eslint-disable-next-line
  const { distributor, language, config, products, page, displayMode } = props

  //
  //  :redux:
  //  We'll need dispatch, our products, and our filtering options.
  const dispatch = useDispatch()
  const SEARCH_STATE = useSelector(selectSearchState)

  //
  //  :rendering:
  //  Return our JSX for this component.
  const renderPriceWindowsFilterUI = () => {
    //
    //  For each of our brand filters, write a checkbox to the DOM for them.
    const ui = []
    for (const [filterId, config] of Object.entries(SEARCH_STATE.filters.prices)) {
      ui.push(
        <div key={filterId} className="flex">
          <label className="cursor-pointer">
            <input
              className="ml-2 mr-2 scale-125"
              type="checkbox"
              name={filterId}
              id=""
              onChange={e => {
                //
                //  +:logging:
                console.log(`:logging:`, `${e.target.checked ? 'enabled' : 'disabled'} filter`, e.target.name)
                e.target.checked
                  ? APP_CORE.log.send(
                      { name: e.target.name },
                      'logEnablePriceFilter'
                    )
                  : APP_CORE.log.send(
                      { name: e.target.name },
                      'logDisablePriceFilter'
                    )
                //
                //  :step 1:
                //  When changing the value, enable/disable the filter in redux.
                e.target.checked
                  ? dispatch(updateSearchFilteringEnablePriceWindow(e.target.name))
                  : dispatch(updateSearchFilteringDisablePriceWindow(e.target.name))
                //
                //  :step 2:
                //  Trigger an update for our search results.
                dispatch(updateSearchProductsDisplay())
                smoothScrollToTopOfApp()
              }}
              checked={config.active}
            />
            {filterId}
          </label>
        </div>
      )
    }
    return <div className="h-32 overflow-auto shadow-inner py-2">{ui}</div>
  }

  return <div className="flex-1">{renderPriceWindowsFilterUI()}</div>
}
