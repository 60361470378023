/*

  s h o w c a s e  s t a t i c s
  Showcase Statics

  :description:
  A file to hold onto static values and enums related to showcase.

*/

//
//  :redux-initial-states:
//  As it's very structed inside a redux state, we've got to be careful within naming/calling things correctly.
//  We'll abstract each "section" of our initial state so that we can use it to initialise and then later to
//  to reset the value of the state without needing to safe-copy and object reference.

const _initialStateSearchProducts = () => {
  return {
    all: [],
    display: []
  }
}

const _initialStateSearchOrdering = () => {
  return {
    key: null,
    direction: 1,
    orders: [
      { name: 'Brand - A to Z', key: 'brand', direction: 1 },
      { name: 'Brand - Z to A', key: 'brand', direction: -1 },
      { name: 'Price - low to high', key: 'price', direction: 1 },
      { name: 'Price - high to low', key: 'price', direction: -1 }
    ]
  }
}

const _initialStateSearchPages = () => {
  return {
    current: 0,
    total: 0,
    size: 20,
    sizes: [
      { name: '20', size: 20 },
      { name: '40', size: 40 },
      { name: 'All', size: 1000000 }
    ]
  }
}

export const renderInitialStateSearchFilters = () => {
  //
  //  :step 1:
  //  Define our empty state.
  const state = {
    text: '',
    brands: {},
    categories: {},
    prices: {
      '€0 to €39.99': {
        from: 0,
        to: 39.99,
        text: '€0 to €39.99',
        active: false,
        _id: 0
      },
      '€40 to €49.99': {
        from: 40,
        to: 49.99,
        text: '€40 to €49.99',
        active: false,
        _id: 1
      },
      '€50 to €59.99': {
        from: 50,
        to: 59.99,
        text: '€50 to €59.99',
        active: false,
        _id: 2
      },
      '€60 to €69.99': {
        from: 60,
        to: 69.99,
        text: '€60 to €69.99',
        active: false,
        _id: 3
      },
      '€70 to €79.99': {
        from: 70,
        to: 79.99,
        text: '€70 to €79.99',
        active: false
        ,
        _id: 4
      },
      '€80 to €89.99': {
        from: 80,
        to: 89.99,
        text: '€80 to €89.99',
        active: false,
        _id: 5
      },
      '€90 to €99.99': {
        from: 90,
        to: 99.99,
        text: '€90 to €99.99',
        active: false,
        _id: 6
      },
      '€100 to €499.99': {
        from: 100,
        to: 499.99,
        text: '€100 to €499.99',
        active: false,
        _id: 7
      },
      '€500 to €999.99': {
        from: 500,
        to: 999.99,
        text: '€500 to €999.99',
        active: false,
        _id: 8
      },
      '€1000+': {
        from: 1000,
        to: 1000000,
        text: '€1000+',
        active: false,
        _id: 9
      }
    }
  }
  //
  //  All done, return our state to the caller.
  return state
}

export const renderInitialStateSearch = () => {
  return {
    initialised: false,
    hash: null,
    products: _initialStateSearchProducts(),
    filters: renderInitialStateSearchFilters(),
    ordering: _initialStateSearchOrdering(),
    pages: _initialStateSearchPages(),
    text: {
      heading: null,
      showing: null
    }
  }
}

export const INITIAL_STATE_SEARCH = renderInitialStateSearch()
